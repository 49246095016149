
import Parse from 'parse';

const deleteCompanyStatement = async (data) => {

  try {

    const CompanyStatement = Parse.Object.extend('CompanyStatement');
    const companyStatement = new CompanyStatement();

    companyStatement.id = data.id;

    const result = await companyStatement.destroy();

    return result;

  } catch (error) {
    console.error('updateCompanyStatement', error);
    return false;
  }

}

export default deleteCompanyStatement;
