import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import MaterialTable from 'material-table';

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';

import {
  SaveAlt as SaveAltIcon,
} from '@material-ui/icons';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import {
  ButtonWithLoading,
} from '../../../../components';

const HorseTransactions = ({
  data,
  isSaving,
  onCreate,
  onDelete,
  onUpdate,
}) => {

  const [ newData, setNewData ] = useState({
    date: new Date(),
    type: 'expense',
    description: '',
    amount: 0,
    invoiceURL: '',
  });

  const handleFieldChange = (name, value) => {
    setNewData({
      ...newData,
      [name]: value
    });
  }

  const handleClick = () => onCreate(newData);

  const columns = [
    { title: 'Date', field: 'date', type: 'date' },
    {
      title: 'Type',
      field: 'type',
      type: 'string',
      lookup: {
        expense: 'Expense',
        income: 'Income',
        management: 'Management',
      },
    },
    { title: 'Description', field: 'description', type: 'string' },
    {
      title: 'Amount',
      field: 'amount',
      type: 'currency',
      align: 'left',
    },
    {
      title: 'Invoice',
      field: 'invoiceURL',
      cellStyle: {paddingTop: 0, paddingBottom: 0,},
      render: ({ invoiceURL }) => (
        <>
          {invoiceURL ?
            <IconButton
              component={Link}
              href={invoiceURL}
              rel="noopener"
              target="_blank"
            >
              <SaveAltIcon />
            </IconButton> :
            null
          }
        </>
      )
    },
    {
      title: 'Company Invoice',
      field: 'companyInvoiceUrl',
      cellStyle: {paddingTop: 0, paddingBottom: 0,},
      render: ({ companyInvoiceUrl }) => (
        <>
          {companyInvoiceUrl ?
            <IconButton
              component={Link}
              href={companyInvoiceUrl}
              rel="noopener"
              target="_blank"
            >
              <SaveAltIcon />
            </IconButton> :
            null
          }
        </>
      )
    },
  ];

  return (
    <Card>
      <CardHeader title="Horse Transactions" />
      <Divider />
      <CardContent style={{ padding: 0, paddingBottom: 0 }}>

        {/* Add data panel */}
        <div style={{ padding: 16 }}>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              xs={6}
              md={3}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disabled={isSaving}
                  disableToolbar
                  format="dd/MM/yyyy"
                  fullWidth
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  label="Date"
                  name="date"
                  onChange={(dateString) => handleFieldChange('date', dateString)}
                  value={newData.date}
                  variant="inline"
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid
              item
              xs={6}
              md={3}
            >
              <FormControl fullWidth>
                <InputLabel htmlFor="transactionType">Transaction Type</InputLabel>
                <Select
                  disabled={isSaving}
                  inputProps={{
                    name: 'type',
                    id: 'type'
                  }}
                  onChange={(event) => handleFieldChange('type', event.target.value)}
                  value={newData.type}
                >
                  <MenuItem value={'expense'}>Expense</MenuItem>
                  <MenuItem value={'income'}>Income</MenuItem>
                  <MenuItem value={'management'}>Management</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={6}
              md={3}
            >
              <TextField
                disabled={isSaving}
                fullWidth
                label="Description"
                name="description"
                onChange={(event) => handleFieldChange('description', event.target.value)}
                value={newData.description}
              />
            </Grid>
            <Grid
              item
              xs={6}
              md={3}
            >
              <TextField
                disabled={isSaving}
                fullWidth
                label="Amount"
                name="amount"
                onChange={(event) => handleFieldChange('amount', event.target.value)}
                type="number"
                value={newData.amount}
              />
            </Grid>
            <Grid
              item
              xs={6}
              md={3}
            >
              <TextField
                disabled={isSaving}
                fullWidth
                label="Invoice"
                name="invoiceURL"
                onChange={(event) => handleFieldChange('invoiceURL', event.target.value)}
                value={newData.invoiceURL}
              />
            </Grid>
            <Grid
              item
              xs={6}
              md={3}
            >
              <TextField
                disabled={isSaving}
                fullWidth
                label="Company Invoice"
                name="companyInvoiceUrl"
                onChange={(event) => handleFieldChange('companyInvoiceUrl', event.target.value)}
                value={newData.companyInvoiceUrl}
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <ButtonWithLoading
                color="primary"
                defaultLabel="Add Data"
                disabled={isSaving}
                isLoading={isSaving}
                loadingLabel="Adding..."
                onClick={handleClick}
                variant="contained"
              />
            </Grid>
          </Grid>
        </div>

        <Divider />

        {/* Table */}
        <MaterialTable
          columns={columns}
          components={{
            Container: props => <div>{props.children}</div>,
          }}
          data={data}
          editable={{
            onRowDelete: onDelete,
            onRowUpdate: onUpdate,
          }}
          options={{
            actionsColumnIndex: -1,
            search: false,
            paging: false,
            draggable: false,
            showTitle: false,
            toolbar: false,
          }}
        />
      </CardContent>
    </Card>
  );
}

export default HorseTransactions;
