import Parse from 'parse';

const parseImage = (image) => {
  // Image is parse object from server with url
  // Image has no change, return the original parse object
  if (image && image.url) {
    return image;
  }

  // Image is a local file object
  // Image has change, create parse image object for upload to server
  if (image && !image.url) {
    return new Parse.File(image.name, image);
  }

  // No image attached, return null
  return null;
}

async function saveHorseDetails (horseData, callback) {

  const Horse = Parse.Object.extend('Horse');
  const query = new Parse.Query(Horse);

  const horse = await query.get(horseData.id);

  horse.set('serialNumber', horseData.serialNumber);
  horse.set('dateOfBirth', horseData.dateOfBirth);
  horse.set('horseName', horseData.horseName);
  horse.set('gender', horseData.gender);
  horse.set('dam', horseData.dam);
  horse.set('horseType', horseData.horseType);
  horse.set('breedingStud', horseData.breedingStud);
  horse.set('coatColor', horseData.coatColor);
  horse.set('sire', horseData.sire);
  horse.set('pedigreeUrl', horseData.pedigreeUrl);
  horse.set('trainer', horseData.trainer);
  horse.set('countryOfOrigin', horseData.countryOfOrigin);
  horse.set('presentStatus', horseData.presentStatus);
  horse.set('trainingPlace', horseData.trainingPlace);
  horse.set('image', parseImage(horseData.image));
  horse.set('investmentType', horseData.investmentType);
  horse.set('horseValue', parseFloat(horseData.horseValue));
  horse.set('isSold', horseData.isSold);

  const result = await horse.save();

  if (callback) callback();

}

export default saveHorseDetails;
