
import Parse from 'parse';

const updateCompanyStatement = async (data) => {

  try {

    const CompanyStatement = Parse.Object.extend('CompanyStatement');
    const companyStatement = new CompanyStatement();

    // Set the data id if it's an update
    if (data.id) companyStatement.id = data.id;

    companyStatement.set('statementDate', new Date(data.statementDate));
    companyStatement.set('totalCurrentAssets', parseFloat(data.totalCurrentAssets));
    companyStatement.set('totalAssets', parseFloat(data.totalAssets));
    companyStatement.set('totalCurrentLiabilities', parseFloat(data.totalCurrentLiabilities));
    companyStatement.set('totalLiabilities', parseFloat(data.totalLiabilities));
    companyStatement.set('totalEquity', parseFloat(data.totalEquity));
    companyStatement.set('totalTradingIncome', parseFloat(data.totalTradingIncome));
    companyStatement.set('grossProfit', parseFloat(data.grossProfit));
    companyStatement.set('netProfit', parseFloat(data.netProfit));
    companyStatement.set('netCashFlowsFromOperatingActivities', parseFloat(data.netCashFlowsFromOperatingActivities));
    companyStatement.set('supplierPurchase', parseFloat(data.supplierPurchase));
    companyStatement.set('creditSales', parseFloat(data.creditSales));
    companyStatement.set('averageAccountsPayable', parseFloat(data.averageAccountsPayable));
    companyStatement.set('avarageAccountsReceivable', parseFloat(data.avarageAccountsReceivable));

    const statement = await companyStatement.save();

    const payload = {
      id: statement.id,
      statementDate: statement.get('statementDate'),
      totalCurrentAssets: statement.get('totalCurrentAssets'),
      totalAssets: statement.get('totalAssets'),
      totalCurrentLiabilities: statement.get('totalCurrentLiabilities'),
      totalLiabilities: statement.get('totalLiabilities'),
      totalEquity: statement.get('totalEquity'),
      totalTradingIncome: statement.get('totalTradingIncome'),
      grossProfit: statement.get('grossProfit'),
      netProfit: statement.get('netProfit'),
      netCashFlowsFromOperatingActivities: statement.get('netCashFlowsFromOperatingActivities'),
      supplierPurchase: statement.get('supplierPurchase'),
      creditSales: statement.get('creditSales'),
      averageAccountsPayable: statement.get('averageAccountsPayable'),
      avarageAccountsReceivable: statement.get('avarageAccountsReceivable'),
    }

    return payload;

  } catch (error) {
    console.error('updateCompanyStatement', error);
    return false;
  }

}

export default updateCompanyStatement;
