import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import CustomerPortalAccess from './CustomerPortalAccess';

import {
  createPortalUserAction,
  deletePortalUserAction,
  fetchPortalUserAction,
  updatePortalUserAction
} from './actions';

const CustomerPortalAccessContainer = () => {

  const { customerId } = useParams();

  const [credentials, setCredentials] = useState({
    id: null,
    username: '',
    password: '',
  });
  const [hasAccess, setHasAccess] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [isLoading, setIsloading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetchPortalUser();
  } ,[])

  const fetchPortalUser = async () => {
    setIsloading(true);
    const fetchedUser = await fetchPortalUserAction(customerId);

    if (fetchedUser) {
      setCredentials(fetchedUser);
      setHasAccess(true);
    }

    setIsloading(false);
  }

  const handleFieldChange = (event) => {
    const newState = {
      ...credentials,
      [event.target.name]: event.target.value
    }
    setCredentials(newState);
  }

  async function handleSubmit() {

    setIsSubmitting(true);

    if (!credentials.id) {
      await createPortalUserAction(customerId, credentials);
    } else {
      await updatePortalUserAction(credentials);
    }

    setIsSubmitting(false);
    setIsRegistering(false);

    fetchPortalUser();

  }

  async function handleDelete() {
    setIsSubmitting(true);
    await deletePortalUserAction(credentials.id);
    setIsSubmitting(false);
    setHasAccess(false);
    fetchPortalUser();
  }

  const handleCancelGrantAccess = (e) => {
    e.preventDefault();
    setIsRegistering(false);
  }

  const handleGrantAccess = (e) => {
    e.preventDefault();
    setIsRegistering(true);
  }

  return (
    <CustomerPortalAccess
      credentials={credentials}
      hasAccess={hasAccess}
      isLoading={isLoading}
      isRegistering={isRegistering}
      isSubmitting={isSubmitting}
      onCancelGrantAccess={handleCancelGrantAccess}
      onDelete={handleDelete}
      onFieldChange={handleFieldChange}
      onGrantAccess={handleGrantAccess}
      onSubmit={handleSubmit}
    />
  );
}

export default CustomerPortalAccessContainer;
