import Parse from 'parse';
import { fetchInvestmentCustomers } from './index';

const deleteInvestmentCustomer = async (investmentCustomerData) => {
  try {

    // set invester customer object
    const InvestmentCustomer = Parse.Object.extend('InvestmentCustomer');
    const investmentCustomer = new InvestmentCustomer();
    investmentCustomer.id = investmentCustomerData.id;

    // set investment customer summary query
    const investmentCustomerSummaryQuery = new Parse.Query('InvestmentCustomerSummary');
    investmentCustomerSummaryQuery.equalTo('investmentCustomer', investmentCustomer);

    // Delete invester customer and get investment customer summary
    const [_, investmentCustomerSummary] = await Promise.all([
      investmentCustomer.destroy(),
      investmentCustomerSummaryQuery.first(),
    ])

    if (investmentCustomerSummary) {
      // Delete investment customer summary if found
      await Parse.Object.destroyAll(investmentCustomerSummary);
    }

    // Get updated investor customers
    const payload = await fetchInvestmentCustomers();

    return payload;

  } catch (error) {
    console.error('deleteInvestmentCustomer error', error);
    return [];
  }

}

export default deleteInvestmentCustomer;
