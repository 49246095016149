import Parse from 'parse';
import { fetchCompanyTransactions } from './index';

/**
 * Save company transaction
 * @param {object} data
 * @param {string} method - create | update | delete
 */
const saveCompanyTransaction = async (data, method = 'create') => {
  try {

    const CompanyTransaction = Parse.Object.extend('CompanyTransaction');
    const transaction = new CompanyTransaction();

    // Add market id if is update
    if ((method === 'update' || method === 'delete') && data.id) {
      transaction.id = data.id;
    }

    if (method === 'delete') {
      // Delete transaction data
      await transaction.destroy();
    } else {
      transaction.set('date', new Date(data.date));
      transaction.set('type', data.type);
      transaction.set('amount', parseFloat(data.amount));
      transaction.set('description', String(data.description));
      transaction.set('invoiceUrl', String(data.invoiceUrl));

      // Save transaction data
      await transaction.save();
    }

    // Get updated market fata list
    const payload = await fetchCompanyTransactions();

    return payload;

  } catch (error) {
    console.error('saveMarketData error', error);
    return [];
  }

}

export default saveCompanyTransaction;
