import Parse from 'parse';

const fetchInvestmentCustomers = async () => {
  try {
    const query = new Parse.Query('InvestmentCustomer');
    query.include('customer');

    const [ investmentCustomers, investmentCustomerSummaries ] = await Promise.all([
      query.include('customer').find(),
      new Parse.Query('InvestmentCustomerSummary').find(),
    ]);

    let investmentCustomerSummariesMap = {};

    for (let index = 0; index < investmentCustomerSummaries.length; index++) {
      const investmentCustomerSummary = investmentCustomerSummaries[index];
      investmentCustomerSummariesMap = {
        ...investmentCustomerSummariesMap,
        [investmentCustomerSummary.get('investmentCustomer').id]: investmentCustomerSummary,
      }
    }

    const payload = investmentCustomers.map((investmentCustomer) => {

      const customer = investmentCustomer.get('customer');

      const summary = investmentCustomerSummariesMap[investmentCustomer.id];

      return {
        id: investmentCustomer.id,
        customerId: customer.id,
        customerName: customer.get('firstName') + ' ' + customer.get('lastName'),
        joinedDate: investmentCustomer.get('joinedDate'),
        shares: investmentCustomer.get('shares'),
        totalIncome: summary ? summary.get('totalIncome') : 0,
        totalExpense: summary ? summary.get('totalExpense') : 0,
      }

    });

    return payload;

  } catch (error) {
    console.error('fetchInvestmentHorses error', error);
    return [];
  }

}

export default fetchInvestmentCustomers;
