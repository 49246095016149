import Parse from 'parse';

/**
 * Fetch Investment Horse Shares
 * @param {string} investmentHorseId
 * @return {array}
 */
const fetchInvestmentHorseShares = async (investmentHorseId) => {
  try {
    if (!investmentHorseId) throw new Error('Invalid investment horse Id');

    const investmentHorse = new (Parse.Object.extend('InvestmentHorse'))();
    investmentHorse.id = investmentHorseId;

    const query = new Parse.Query('InvestmentHorseShare');
    query.equalTo('investmentHorse', investmentHorse);
    query.descending('startDate');

    const response = await query.find();

    return response.map((share) => ({
      id: share.id,
      sharePercentage: share.get('sharePercentage'),
      startDate: share.get('startDate'),
    }));

  } catch (error) {
    console.error('fetchInvetmentHorseShares error', error);
  }
}

export default fetchInvestmentHorseShares;
