import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

import { CustomerProfileForm, Title } from '../../components';
import { routes } from '../../const';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3),
  },
  backLink: {
    display: 'inline-block',
    marginBottom: theme.spacing(3),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1) / -2,
  },
  formGroup: {
    marginBottom: theme.spacing(4),
  }
}));

export default function AddCustomer() {

  const classes = useStyles();

  return (
    <>
      <Grid
        item
        xs={12}
      >
        <Link
          className={classes.backLink}
          component={RouterLink}
          to={routes.CUSTOMERS}
        >
          Back to Customers List
        </Link>
        <Title>Add a customer</Title>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <CustomerProfileForm />
      </Grid>
    </>
  );
}
