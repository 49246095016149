/* eslint-disable no-script-url */

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { DataConsumer } from '../../../../utils/DataContext';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { routes } from '../../../../const';

export default function CustomerList() {
  return (
    <React.Fragment>
      <Table
        size="small"
      >
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Mobile</TableCell>
            <TableCell align="right">Edit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <DataConsumer>
            {(customers) => (
              customers.map(customer => (
                <TableRow key={customer.id}>
                  <TableCell>{customer.name}</TableCell>
                  <TableCell>{customer.email}</TableCell>
                  <TableCell>{customer.mobile}</TableCell>
                  <TableCell align="right" padding="none">
                    <IconButton component={RouterLink} to={`${routes.CUSTOMER_DETAILS}/${customer.id}`}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </DataConsumer>
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
