import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import { fetchCustomerDetails, updateCustomerDetails } from '../../actions';

import { CustomerProfileForm, Spinner } from '../../../../components';

import { DataProvider } from '../../../../utils/DataContext';

const initialCustomerState = {
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: '',
  email: '',
  landline: '',
  mobile: '',
  businessName: '',
  jobTitle: '',
  invoiceTitle: '',
  bankAccount: '',
  gstNumber: '',
}

const CustomerProfileContainer = () => {

  const [customer, setCustomer] = useState(initialCustomerState);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { customerId } = useParams();
  const history = useHistory();

  async function fetchCustomerProfile() {
    const customer = await fetchCustomerDetails(customerId);
    setCustomer(customer);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchCustomerProfile();
  }, []);

  const handleCancel = () => {
    history.push('/customers');
  }

  const handleFieldChange = (event) => {
    const newState = {
      ...customer,
      [event.target.name]: event.target.value
    }
    setCustomer(newState);
  }

  const handleDateFieldChange = (dateString) => {
    const newState = {
      ...customer,
      dateOfBirth: dateString
    }
    setCustomer(newState);
  }

  const handleSubmit = () => {
    setIsSubmitting(true);
    updateCustomerDetails(
      customer,
      () => setIsSubmitting(false)
    );
  }

  const customerProfileContext = {
    customer,
    handleCancel,
    handleDateFieldChange,
    handleFieldChange,
    handleSubmit,
    isSubmitting,
  }

  return (
    <DataProvider value={customerProfileContext}>
      {isLoading ? <Spinner /> : <CustomerProfileForm />}
    </DataProvider>
  );
}

export default CustomerProfileContainer;
