import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { HorseProfileForm, Spinner, Title } from '../../components';
import { HorseNews, HorseShares, HorseTransactions } from './components';
import { routes } from '../../const';

import useStyles from './styles';

const HorseDetails = ({
  handleTabChange,
  isLoading,
  tabValue,
  transactions,
}) => {

  const classes = useStyles();

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  let content;

  switch (tabValue) {
    case 0:
      content = <HorseProfileForm />
      break;
    case 1:
      content = <HorseTransactions transactions={transactions} />
      break;
    case 2:
      content = <HorseShares />
      break;
    case 3:
      content = <HorseNews />
      break;
    default:
      content = <HorseShares />
      break;
  }


  return (
    <>
      <Grid item xs={12}>
        <Link component={RouterLink} to={routes.HORSES} className={classes.backLink}>
          Back to Horses List
        </Link>
        <Title>Horse Details</Title>
      </Grid>
      <Grid item xs={12}>
        <AppBar position="static" className={classes.tab}>
          <Tabs
            aria-label="simple tabs example"
            indicatorColor="secondary"
            onChange={handleTabChange}
            value={tabValue}
          >
            <Tab label="Profile" {...a11yProps(0)} />
            <Tab label="Transactions" {...a11yProps(1)} />
            <Tab label="Syndication" {...a11yProps(2)} />
            <Tab label="Related News" {...a11yProps(3)} />
          </Tabs>
        </AppBar>
      </Grid>
      <Grid item xs={12}>
        {isLoading ? <Spinner /> : content}
      </Grid>
    </>
  );
}

export default HorseDetails;
