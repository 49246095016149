import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  previewFigure: {
    display: 'inline-block',
    margin: 0,
    cursor: 'pointer',
  },
  previewImage: {
    maxHeight: 300,
  },
  removeImage: {
    cursor: 'pointer',
  }
}));

const ImageUploader = ({
  imageURL,
  onImageChange
}) => {
  const classes = useStyles();

  const [ image, setImage ] = useState();

  const handleChange = (e) => {
    const fileInput = e.target;
    if (fileInput.files && fileInput.files[0]) {
      const imageFile = fileInput.files[0];
      const objectUrl = URL.createObjectURL(imageFile);
      setImage(objectUrl);
      onImageChange(imageFile);
    }
  }

  const handleRemoveImage = (e) => {
    e.preventDefault();
    setImage('');
    onImageChange('');
  }

  return (
    <div>
      <input
        accept="image/*"
        className={classes.input}
        id="contained-button-file"
        multiple
        type="file"
        onChange={handleChange}
      />
      <label htmlFor="contained-button-file">
        { (image || imageURL) ?
          <>
            <figure className={classes.previewFigure}>
              <img
                src={image || imageURL}
                className={classes.previewImage}
              />
              <Typography component="figcaption" variant="caption">
                Click the image to edit or update
              </Typography>
            </figure>
            <br />
            <Link
              className={classes.removeImage}
              component="span"
              onClick={handleRemoveImage}
            >
              Remove image
            </Link>
          </> :
          <Button variant="contained" component="span">
            Upload Image
          </Button>
        }
      </label>
    </div>
  );
}

export default ImageUploader;
