import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';

import { HorseProfileForm, Title } from '../../components';
import { routes } from '../../const';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3),
  },
  backLink: {
    display: 'inline-block',
    marginBottom: theme.spacing(3),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1) / -2,
  },
  formGroup: {
    marginBottom: theme.spacing(4),
  }
}));

const AddHorse = () => {

  const classes = useStyles();

  return (
    <>
      <Grid
        item
        xs={12}
      >
        <Link
          className={classes.backLink}
          component={RouterLink}
          to={routes.HORSES}
        >
          Back to Horses List
        </Link>
        <Title>Add horse</Title>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <HorseProfileForm />
      </Grid>
    </>
  );
}

export default AddHorse;
