import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import HorseNews from './HorseNews';

import {
  fetchHorseNews,

  // CRUD Horse News
  createHorseNews,
  fetchHorseNewsDetails,
  updateHorseNews,
  deleteHorseNews,
} from '../../actions';

import { parseImageURL } from '../../../../utils';

/* Horse news details */
const INITIAL_NEWS_DETAILS = {
  date: new Date(),
  title: '',
  description: '',
  image: null,
  linkURL: '',
}

const HorseNewsContainer = () => {

  const { horseId } = useParams();

  const [ isLoading, setIsloading] = useState(true);

  // Fetch horse newslist
  const [ horseNews, setHorseNews ] = useState([]);

  async function handleFetchHorseNews() {
    const fetchedHorseNews = await fetchHorseNews(horseId);
    setHorseNews(fetchedHorseNews);
    setIsloading(false);
  }

  useEffect(() => {
    handleFetchHorseNews();
  }, []);

  // News details dialog
  const [newsDetails, setNewsDetails] = useState(INITIAL_NEWS_DETAILS);
  const [isSubmittingNews, setIsSubmittingNews] = useState(false);
  const [isLoadingNewsDetails, setIsLoadingNewsDetails] = useState(false);

  const handleResetDetails = () => {
    setNewsDetails(INITIAL_NEWS_DETAILS);
  }

  async function handleFetchHorseNewsDetails(newsId) {
    setIsLoadingNewsDetails(true);
    const fetchedDetails = await fetchHorseNewsDetails(newsId);
    setNewsDetails(fetchedDetails);
    setIsLoadingNewsDetails(false);
  }

  async function handleCreateHorseNewsSuccess() {
    // Reload news list
    await handleFetchHorseNews();

    // Close create horse news dialog
    handleAddDialogClose();

    // Disable submitting state
    setIsSubmittingNews(false);
  }

  async function handleUpdateHorseNewsSuccess() {
    // Reload news list
    await handleFetchHorseNews();

    // Close create horse news dialog
    handleEditDialogClose();

    // Disable submitting state
    setIsSubmittingNews(false);
  }


  const handleAddDialogSubmit = () => {
    // Enable submitting state
    setIsSubmittingNews(true);

    // Submit news, disable submitting state once done
    createHorseNews(
      newsDetails,
      horseId,
      handleCreateHorseNewsSuccess
    );
  }

  const handleEditDialogSubmit = () => {
    // Enable submitting state
    setIsSubmittingNews(true);

    // Submit news, disable submitting state once done
    updateHorseNews(
      newsDetails,
      handleUpdateHorseNewsSuccess
    );
  }

  const handleNewsDelete = () => {
    // Enable submitting state
    setIsSubmittingNews(true);

    // Submit news, disable submitting state once done
    deleteHorseNews(
      newsDetails,
      handleUpdateHorseNewsSuccess
    );
  }

  const handleFieldChange = (event) => {
    const newState = {
      ...newsDetails,
      [event.target.name]: event.target.value
    }
    setNewsDetails(newState);
  }

  const handleDateFieldChange = (dateString) => {
    const newState = {
      ...newsDetails,
      date: dateString
    }
    setNewsDetails(newState);
  }

  const handleImageChange = (image) => {
    const newState = {
      ...newsDetails,
      image: image
    }
    setNewsDetails(newState);
  }

  const [ addDialogOpen, setAddDialogOpen ] = useState(false);

  const handleAddDialogOpen = () => {
    setAddDialogOpen(true);
  }

  const handleAddDialogClose = () => {
    setAddDialogOpen(false);
    handleResetDetails();
  }

  const [ editDialogOpen, setEditDialogOpen ] = useState(false);

  const handleEditDialogOpen = (newsId) => {
    handleFetchHorseNewsDetails(newsId);
    setEditDialogOpen(true);
  }

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    handleResetDetails();
  }

  return (
    <HorseNews
      addDialogOpen={addDialogOpen}
      editDialogOpen={editDialogOpen}
      isLoading={isLoading}
      isLoadingNewsDetails={isLoadingNewsDetails}
      isSubmittingNews={isSubmittingNews}
      news={horseNews.map((news) => ({
        ...news,
        imageURL: parseImageURL(news.image)
      }))}
      newsDetails={{
        ...newsDetails,
        imageURL: parseImageURL(newsDetails.image)
      }}
      onAddDialogClose={handleAddDialogClose}
      onAddDialogOpen={handleAddDialogOpen}
      onAddDialogSubmit={handleAddDialogSubmit}
      onDateFieldChange={handleDateFieldChange}
      onEditDialogClose={handleEditDialogClose}
      onEditDialogOpen={handleEditDialogOpen}
      onEditDialogSubmit={handleEditDialogSubmit}
      onFieldChange={handleFieldChange}
      onNewsDelete={handleNewsDelete}
      onImageChange={handleImageChange}
    />
  );
}

export default HorseNewsContainer;
