import Parse from 'parse';
import { fetchInitData } from './index';

/**
 * Save owner transaction
 * @param {object} data
 * @param {string} method - create | update | delete
 */
const saveOwnerTransaction = async (data, method = 'create') => {
  try {

    const HorseOwnerTransaction = Parse.Object.extend('HorseOwnerTransaction');
    const transaction = new HorseOwnerTransaction();

    // Point to syndication if is create
    if (method === 'create') {
      const Syndication = Parse.Object.extend('Syndication');
      const syndication = new Syndication();
      syndication.id = data.syndicationId;
      transaction.set('syndication', syndication);
    }

    // Add transaction id if is update
    if ((method === 'update' || method === 'delete') && data.id) {
      transaction.id = data.id;
    }

    if (method === 'delete') {
      // Delete transaction data
      await transaction.destroy();
    } else {

      transaction.set('date', new Date(data.date));
      transaction.set('type', String(data.type));
      transaction.set('description', String(data.description));
      transaction.set('amount', parseFloat(data.amount));
      transaction.set('invoiceURL', String(data.invoiceURL));

      // Save transaction data
      await transaction.save();
    }

    // Get updated syndication data
    const payload = await fetchInitData(data.syndicationId);

    return payload;

  } catch (error) {
    console.error('saveSyndicationShare error', error);
    return [];
  }

}

export default saveOwnerTransaction;
