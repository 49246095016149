import React from 'react';

import { Grid } from '@material-ui/core';

const FormGridItem = ({children}) => (
  <Grid
    item
    xs={2}
    md={3}
    lg={2}
  >
    {children}
  </Grid>
);

export default FormGridItem;
