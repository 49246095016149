import Parse from 'parse';
import { fetchInitData } from './index';

/**
 * Save company transaction
 * @param {object} data
 * @param {string} method - create | update | delete
 */
const saveSyndicationShare = async (data, method = 'create') => {
  try {

    const SyndicationShare = Parse.Object.extend('SyndicationShare');
    const share = new SyndicationShare();

    // Point to syndication if is create
    if (method === 'create') {
      const Syndication = Parse.Object.extend('Syndication');
      const syndication = new Syndication();
      syndication.id = data.syndicationId;
      share.set('syndication', syndication);
    }

    // Add market id if is update
    if ((method === 'update' || method === 'delete') && data.id) {
      share.id = data.id;
    }

    if (method === 'delete') {
      // Delete transaction data
      await share.destroy();
    } else {
      share.set('startDate', new Date(data.startDate));
      share.set('sharePercentage', parseFloat(data.sharePercentage));

      // Save transaction data
      await share.save();
    }

    // Get updated market fata list
    const payload = await fetchInitData(data.syndicationId);

    return payload;

  } catch (error) {
    console.error('saveSyndicationShare error', error);
    return [];
  }

}

export default saveSyndicationShare;
