import Parse from 'parse';

async function fetchHorses() {
  try {
    const Horse = Parse.Object.extend('Horse');
    const query = new Parse.Query(Horse);
    query.ascending('horseName');
    const response = await query.find();

    const payload = response.map((horse) => ({
      id: horse.id,
      name: horse.get('horseName'),
    }));

    return payload;

  } catch (error) {
    console.error('fetchHorses error', error);
    return [];
  }
}

export default fetchHorses;
