import Parse from 'parse';

/**
 * Fetch Investment Horse
 * @param {string} investmentHorseId
 * @return {array}
 */
const fetchInvestmentHorse = async (investmentHorseId) => {
  try {
    if (!investmentHorseId) throw new Error('Invalid investment horse Id');

    const query = new Parse.Query('InvestmentHorse');
    query.equalTo('objectId', investmentHorseId);
    query.include('horse');
    const response = await query.first();

    return {
      id: response.id,
      horseId: response.get('horse').id,
      name: response.get('horse').get('horseName'),
    };

  } catch (error) {
    console.error('fetchInvestmentHorse error', error);
  }
}

export default fetchInvestmentHorse;
