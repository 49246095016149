import Parse from 'parse';

async function fetchHorseNews(horseId) {
  const News = Parse.Object.extend('News');
  const Horse = Parse.Object.extend('Horse');
  const horse = new Horse();
  horse.id = horseId;

  const query = new Parse.Query(News);
  query.equalTo('relatedHorse', horse);
  const response = await query.find();

  const payload = await response
    .map((news) => ({
      id: news.id,
      date: news.get('date'),
      title: news.get('title'),
      description: news.get('description'),
      image: news.get('image'),
      linkURL: news.get('linkURL'),
    }))
    .slice() // Create a copy as sort method is going to mutate the original array
    .sort((a, b) => new Date(b.date) - new Date(a.date));

  return payload;
}

export default fetchHorseNews;
