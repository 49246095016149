import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

import { Topbar, Sidebar } from './components';
import { Copyright } from '../../components';

import { logout } from './actions';

import useStyles from './styles';

import { RouteContext } from '../../App';

const Main = ({ children }) => {
  const classes = useStyles();
  const { setIsAuthenticated } = useContext(RouteContext);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [isLoggingOut, setIsLoggingOut] = React.useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleLogout = async () => {
    setIsLoggingOut(true);
    await logout();
    setIsLoggingOut(false);
    setIsAuthenticated(false);
  }

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <Topbar
          handleDrawerToggle={handleDrawerToggle}
        />
        <Sidebar
          drawerOpen={drawerOpen}
          handleDrawerToggle={handleDrawerToggle}
          onLogout={handleLogout}
        />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container
            className={classes.container}
            maxWidth="xl"
          >
            <Grid
              container
              spacing={3}
            >
              {children}
            </Grid>
          </Container>
          <Copyright />
        </main>
      </div>
      <Dialog
        aria-labelledby="simple-dialog-title"
        fullWidth
        maxWidth="xs"
        open={isLoggingOut}
        style={{ textAlign: 'center' }}
      >
        <DialogTitle id="simple-dialog-title">Logging out</DialogTitle>
        <DialogContent>
          <CircularProgress
            size={24}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

Main.propTypes = {
  children: PropTypes.element,
};

export default Main;
