import Parse from 'parse';

async function LoggingIn({ userName, password }) {
  try {
    const user = await Parse.User.logIn(userName, password);

    // Check if user is an admin
    const adminRoleQuery = new Parse.Query(Parse.Role);
    adminRoleQuery.equalTo('name', 'Administrator');
    adminRoleQuery.equalTo('users', user);

    const admin = await adminRoleQuery.first();

    // Throw error if user is not an Admin
    if (!admin) throw new Error('Not an admin');

    return true;
  } catch (error) {
    // Invalid username/password or user not an admin
    return false
  }

}

export default LoggingIn;
