import Parse from 'parse';

async function updateCustomerDetails (customerData, callback) {

  const Customer = Parse.Object.extend('Customer');
  const query = new Parse.Query(Customer);

  const customer = await query.get(customerData.id);

  customer.set('firstName', customerData.firstName);
  customer.set('middleName', customerData.middleName);
  customer.set('lastName', customerData.lastName);
  customer.set('dateOfBirth', customerData.dateOfBirth);
  customer.set('email', customerData.email);
  customer.set('landline', customerData.landline);
  customer.set('mobile', customerData.mobile);
  customer.set('businessName', customerData.businessName);
  customer.set('jobTitle', customerData.jobTitle);
  customer.set('invoiceTitle', customerData.invoiceTitle);
  customer.set('bankAccount', customerData.bankAccount);
  customer.set('gstNumber', customerData.gstNumber);

  const result = await customer.save();

  if (callback) callback();

}

export default updateCustomerDetails;
