/**
 * Parse Float Number
 * @param {string|number} num
 * @return {number}
 * @example
 * parseFloatNumber('12.12') -> 12.12
 * parseFloatNumber(105) -> 105
 * parseFloatNumber(null) -> 0
 * parseFloatNumber(undefined) -> 0
 */
const parseFloatNumber = (num) => {
  if (typeof num !== 'number' && typeof num !== 'string') return 0;
  if (typeof num === 'number') return num;
  return !num ? 0 : parseFloat(num);
}

export default parseFloatNumber;
