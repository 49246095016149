import React, { useContext, useEffect, useState } from 'react';
import {
  useHistory,
  useParams,
} from 'react-router';

import HorseDetails from './HorseDetails';

import { deleteHorse, fetchHorseDetails, saveHorseDetails } from './actions';

import { RouteContext } from '../../App';

import { DataProvider } from '../../utils/DataContext';

const HorseDetailsContainer = () => {

  const { setCurrentRoute } = useContext(RouteContext);
  setCurrentRoute('horses');

  useEffect(() => {
    setCurrentRoute('horses');
  }, []);

  const { horseId } = useParams();

  const [horse, setHorse] = useState({});
  const [ isLoading, setIsLoading ] = useState(true);
  const [ isDeletingHorse, setIsDeletingHorse ] = useState(false);

  async function fetchHorseDetailsData() {
    const fetchedHorse = await fetchHorseDetails(horseId);
    setHorse(fetchedHorse);
    setIsLoading(false);
  }

  const [tabValue, setTabValue] = React.useState(0);
  const history = useHistory();

  const handleTabChange = (event, newValue) => {
    setIsLoading(true);
    setTabValue(newValue);
    pushBrowserHistory(newValue);
  };

  const pushBrowserHistory = (value) => {

    switch (value) {
      case 0:
        history.push(`/horse/${horseId}/profile`);
        fetchHorseDetailsData();
        break;
      case 1:
        history.push(`/horse/${horseId}/transactions`);
        setIsLoading(false);
        break;
      case 2:
        history.push(`/horse/${horseId}/shares`);
        setIsLoading(false);
        break;
      case 3:
        history.push(`/horse/${horseId}/news`);
        setIsLoading(false);
        break;
      default:
        history.push(`/horse/${horseId}/profile`);
        fetchHorseDetailsData();
        break;
    }
  }

  const { content } = useParams();

  useEffect(() => {
    if (content === 'profile') {
      setTabValue(0);
      fetchHorseDetailsData();
    }

    if (content === 'transactions') {
      setTabValue(1);
      setIsLoading(false);
    }

    if (content === 'shares') {
      setTabValue(2);
      setIsLoading(false);
    }

    if (content === 'news') {
      setTabValue(3);
      setIsLoading(false);
    }
  }, []);

  const handleFieldChange = (event) => {
    const { name, value, checked } = event.target;

    const fieldName = name;
    const fieldValue = name === 'isSold' ? checked : value;

    const newState = {
      ...horse,
      [fieldName]: fieldValue
    }
    setHorse(newState);
  }

  const handleDateFieldChange = (dateString) => {
    const newState = {
      ...horse,
      dateOfBirth: dateString
    }
    setHorse(newState);
  }

  const handleImageChange = (image) => {
    const newState = {
      ...horse,
      image
    }
    setHorse(newState);
  }

  const [isUpdatingHorseDetails, setIsUpdatingHorseDetails] = useState(false);

  const handleSubmit = () => {
    setIsUpdatingHorseDetails(true);
    saveHorseDetails(
      horse,
      () => setIsUpdatingHorseDetails(false)
    );
  }

  const handleHorseDelete = async (e) => {
    e.preventDefault();
    setIsDeletingHorse(true);
    await deleteHorse(horseId);
    history.push('/horses');
  }

  const horseDetailsContext = {
    horse: {
      ...horse,
      imageURL: (horse.image && horse.image.url) ? horse.image.url() : null,
    },
    handleDateFieldChange,
    handleFieldChange,
    handleHorseDelete,
    handleImageChange,
    handleSubmit,
    isDeleting: isDeletingHorse,
    isLoading: isUpdatingHorseDetails,
  }

  return(
    <DataProvider value={horseDetailsContext}>
      <HorseDetails
        handleTabChange={handleTabChange}
        isLoading={isLoading}
        isUpdatingHorseDetails={isUpdatingHorseDetails}
        tabValue={tabValue}
      />
    </DataProvider>
  );
}

export default HorseDetailsContainer;
