import Parse from 'parse';

async function fetchSyndications(horseId) {
  try {

    const Horse = Parse.Object.extend('Horse');
    const horse = new Horse();
    horse.id = horseId;

    const Syndication = Parse.Object.extend('Syndication');
    const query = new Parse.Query(Syndication);

    query.equalTo('horse', horse);
    query.include('customer');

    const response = await query.find();

    const generatePayload = async (syndications) => {
      const output = await syndications.map(async (syndication) => {

        // Get customer name
        const customer = syndication.get('customer');
        const customerFullName = `${customer.get('firstName')} ${customer.get('middleName')} ${customer.get('lastName')}`;

        // Get current shares
        const sharesQuery = new Parse.Query('SyndicationShare');
        sharesQuery.equalTo('syndication', syndication);
        sharesQuery.descending('startDate');
        const share = await sharesQuery.first();

        return {
          id: syndication.id,
          customer: customerFullName,
          customerId: customer.id,
          sharePercentage: share.get('sharePercentage'),
          frozeDepositUntil: syndication.get('frozeDepositUntil'),
        }
      });

      return Promise.all(output);
    }

    const payload = await generatePayload(response);

    return payload;

  } catch (error) {
    console.error('fetchSyndications error', error);
    return [];
  }
}

export default fetchSyndications;
