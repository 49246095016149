import Parse from 'parse';
import { fetchMarketsData } from './index';

const deleteMarketData = async (marketData) => {
  try {

    // Get invester customer
    const Market = Parse.Object.extend('Market');
    const market = new Market();
    market.id = marketData.id;

    // Delete invester customer
    await market.destroy();

    // Get updated investor customers
    const payload = await fetchMarketsData();

    return payload;

  } catch (error) {
    console.error('deleteMarketData error', error);
    return [];
  }

}

export default deleteMarketData;
