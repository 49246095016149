import React, { useContext, useRef, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';

import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@material-ui/core'

import { green } from '@material-ui/core/colors';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { ButtonWithLoading, SubTitle, ImageUploader } from '../../components';

import DataContext from '../../utils/DataContext';

const useStyles = makeStyles(theme => ({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
    color: green[500],
  },
  buttonWrapper: {
    display: 'inline-block',
    position: 'relative',
  },
  paper: {
    padding: theme.spacing(3),
  },
  formGroup: {
    marginBottom: theme.spacing(4),
  },
}));

const HorseProfileForm = () => {

  const {
    horse,
    handleDateFieldChange,
    handleFieldChange,
    handleHorseDelete,
    handleImageChange,
    handleSubmit,
    isDeleting,
    isLoading,
  } = useContext(DataContext);

  const {
    hasSyndication,
    horseName,
    horseType,
    serialNumber,
    gender,
    dateOfBirth,
    breedingStud,
    coatColor,
    sire,
    dam,
    trainer,
    countryOfOrigin,
    trainingPlace,
    presentStatus,
    pedigreeUrl,
    imageURL,
    horseValue,
  } = horse;

  const classes = useStyles();

  const [error, setError] = useState(false);

  const scrollToRef = (ref) => {
    ref.current.scrollIntoView();
  }

  const formRef = useRef(null)

  const handleBeforeFormSubmit = (event) => {
    event.preventDefault();

    // If horse name is empty, display error state
    if (horseName.length <= 0) {
      setError(true);
      scrollToRef(formRef);
      return;
    }

    // Clear error state if there's any
    if (error) setError(false);

    // Submit form
    handleSubmit();
  }

  return(
    <Paper
      className={classes.paper}
      ref={formRef}
    >
      <SubTitle>Horse Details</SubTitle>
      <Grid
        container
        spacing={6}
      >
        <Grid
          className={classes.formGroup}
          item
          xs={6}
        >
          <TextField
            error={error}
            fullWidth
            helperText={error ? 'This is a required field' : null}
            label="Horse Name"
            margin="normal"
            name="horseName"
            onChange={handleFieldChange}
            required
            value={horseName || ''}
          />
          <TextField
            fullWidth
            label="Serial Number"
            margin="normal"
            name="serialNumber"
            onChange={handleFieldChange}
            value={serialNumber || ''}
          />
          <FormControl
            fullWidth
            margin="normal"
          >
            <InputLabel htmlFor="gender">Gender</InputLabel>
            <Select
              inputProps={{
                name: 'gender',
                id: 'gender'
              }}
              onChange={handleFieldChange}
              value={gender || ''}
            >
              <MenuItem value={'Filly'}>Filly</MenuItem>
              <MenuItem value={'Mare'}>Mare</MenuItem>
              <MenuItem value={'Colt'}>Colt</MenuItem>
              <MenuItem value={'Stallion'}>Stallion</MenuItem>
              <MenuItem value={'Gelding'}>Gelding</MenuItem>
            </Select>
          </FormControl>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              format="dd/MM/yyyy"
              fullWidth
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              label="Date of Birth"
              margin="normal"
              name="dateOfBirth"
              onChange={handleDateFieldChange}
              value={dateOfBirth || new Date()}
              variant="inline"
            />
          </MuiPickersUtilsProvider>
          <TextField
            fullWidth
            label="Breeding Stud"
            margin="normal"
            name="breedingStud"
            onChange={handleFieldChange}
            value={breedingStud || ''}
          />
          <FormControl
            fullWidth
            margin="normal"
          >
            <InputLabel htmlFor="coatColor">Coat Color</InputLabel>
            <Select
              inputProps={{
                name: 'coatColor',
                id: 'coatColor'
              }}
              onChange={handleFieldChange}
              value={coatColor || ''}
            >
              <MenuItem value={'Bay'}>Bay</MenuItem>
              <MenuItem value={'Chestnut'}>Chestnut</MenuItem>
              <MenuItem value={'Brown'}>Brown</MenuItem>
              <MenuItem value={'Black'}>Black</MenuItem>
              <MenuItem value={'Grey'}>Grey</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="horseType">Horse Type</InputLabel>
            <Select
              inputProps={{
                name: 'horseType',
                id: 'horseType'
              }}
              onChange={handleFieldChange}
              value={horseType || ''}
            >
              <MenuItem value={'Breeding'}>Breeding</MenuItem>
              <MenuItem value={'Trade'}>Trade</MenuItem>
              <MenuItem value={'Race'}>Race</MenuItem>
              <MenuItem value={'Sold'}>Sold</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Horse Value"
            margin="normal"
            name="horseValue"
            onChange={handleFieldChange}
            type="number"
            value={horseValue || ''}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Sire"
            margin="normal"
            name="sire"
            onChange={handleFieldChange}
            value={sire|| ''}
          />
          <TextField
            fullWidth
            label="Dam"
            margin="normal"
            name="dam"
            onChange={handleFieldChange}
            value={dam || ''}
          />
          <TextField
            fullWidth
            label="Trainer"
            margin="normal"
            name="trainer"
            onChange={handleFieldChange}
            value={trainer || ''}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="countryOfOrigin">Country of Origin</InputLabel>
            <Select
              inputProps={{
                name: 'countryOfOrigin',
                id: 'countryOfOrigin'
              }}
              onChange={handleFieldChange}
              value={countryOfOrigin || ''}
            >
              <MenuItem value={'New Zealand'}>New Zealand</MenuItem>
              <MenuItem value={'Australia'}>Australia</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="trainingPlace">Training Place</InputLabel>
            <Select
              inputProps={{
                name: 'trainingPlace',
                id: 'trainingPlace'
              }}
              onChange={handleFieldChange}
              value={trainingPlace || ''}
            >
              <MenuItem value={'New Zealand'}>New Zealand</MenuItem>
              <MenuItem value={'Australia'}>Australia</MenuItem>
              <MenuItem value={'Singapore'}>Singapore</MenuItem>
              <MenuItem value={'Hong Kong'}>Hong Kong</MenuItem>
              <MenuItem value={'Malaysia'}>Malaysia</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Pedigree Link"
            margin="normal"
            name="pedigreeUrl"
            onChange={handleFieldChange}
            value={pedigreeUrl || ''}
          />
          <TextField
            fullWidth
            label="Present Status"
            margin="normal"
            multiline
            name="presentStatus"
            onChange={handleFieldChange}
            value={presentStatus || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <SubTitle>Horse Image</SubTitle>
          <ImageUploader
            imageURL={imageURL}
            onImageChange={handleImageChange}
          />
        </Grid>
        <Grid item xs={12} style={{ display: 'flex' }}>
          <div
            className={classes.buttonWrapper}
            style={{ marginRight: 'auto' }}
          >
            <Button
              color="primary"
              disabled={isLoading || isDeleting}
              onClick={handleBeforeFormSubmit}
              variant="contained"
            >
              {isLoading ? 'Saving changes' : 'Save changes'}
            </Button>
            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
          {handleHorseDelete !== undefined ?
            <ButtonWithLoading
              color="secondary"
              defaultLabel="Delete"
              disabled={isLoading || hasSyndication}
              isLoading={isDeleting}
              loadingLabel="Deleting..."
              onClick={handleHorseDelete}
              variant="contained"
            />
            : null
          }
        </Grid>
      </Grid>
    </Paper>
  );
}

export default HorseProfileForm;
