import Parse from 'parse';

import { parseImage } from '../../../utils';

async function createHorseNews(newsData, horseId, successCallBack) {
  const News = Parse.Object.extend('News');
  const news = new News();

  const Horse = Parse.Object.extend('Horse');
  const horseQuery = new Parse.Query(Horse);
  horseQuery.equalTo('objectId', horseId);

  const horse = await horseQuery.first();

  const newNews = {
    ...newsData,
    image: parseImage(newsData.image),
    relatedHorse: horse
  }

  news.set(newNews);

  news.save().then(
    (result) => {
      successCallBack();
    },
    (error) => {
      console.error('Error while creating ParseObject: ', error);
    }
  );
}

export default createHorseNews;
