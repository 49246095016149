import React, { useContext, useEffect, useState } from 'react';

import { RouteContext } from '../../App';

import { fetchInvestmentProduct } from './actions';

import Investment from './Investment';

export const InvestmentContext = React.createContext();

const InvestmentContainer = () => {

  const { setCurrentRoute } = useContext(RouteContext);

  const [ investmentProduct, setInvestmentProduct ] = useState({});
  const [ isLoading, setIsLoading ] = useState(true);

  useEffect(() => {
    setCurrentRoute('investment');
    _fetchInvestmentProduct();
  }, []);

  /**
   * Fetch Investment Product
   */
  async function _fetchInvestmentProduct() {

    try {
      const response = await fetchInvestmentProduct();
      setInvestmentProduct(response);
    } catch (error) {
      console.error('fetchInvestmentProduct error', error);
    } finally {
      setIsLoading(false);
    }
  }

  // Construct Investment Context
  const investmentContext = {
    investmentProduct,
  }

  return(
    <InvestmentContext.Provider value={investmentContext}>
      <Investment
        isLoading={isLoading}
      />
    </InvestmentContext.Provider>
  );
}

export default InvestmentContainer;
