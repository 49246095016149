import Parse from 'parse';

const saveHorse = async(horseData) => {

  try {

    const Horse = Parse.Object.extend('Horse');
    const horse = new Horse();

    const imageFile = horseData.image;

    const parseFile = imageFile ? new Parse.File(imageFile.name, imageFile) : null;

    const newHorse = {
      ...horseData,
      image: parseFile,
    }

    horse.set(newHorse);

    const result = await horse.save();

    window.location.href = `/horse/${result.id}`;

  } catch (error) {
    console.error('Error while creating ParseObject: ', error);
  }

}

export default saveHorse;
