import Parse from 'parse';

const parseImage = (image) => {
  // Image is parse object from server with url
  // Image has no change, return the original parse object
  if (image && image.url) {
    return image;
  }

  // Image is a local file object
  // Image has change, create parse image object for upload to server
  if (image && !image.url) {
    return new Parse.File(image.name, image);
  }

  // No image attached, return null
  return null;
}

export default parseImage;
