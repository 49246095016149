import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { Spinner, Title } from '../../components';
import { CustomersList } from './components';
import { routes } from '../../const';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  leftIcon: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1) / -2,
  }
}));

export default function CustomerPage({
  isLoading,
}) {

  const classes = useStyles();

  return (
    <>
      <Grid item xs={12} md={4} lg={3}>
        <Button variant="contained" color="primary" component={RouterLink} to={routes.ADD_CUSTOMER}>
          <AddIcon className={classes.leftIcon} />
          Add customer
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Title>All Customers</Title>
          {isLoading ? <Spinner /> : <CustomersList />}
        </Paper>
      </Grid>
    </>
  );
}
