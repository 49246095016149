
import Parse from 'parse';

const updateGeneralConfig = async (data) => {

  try {

    const InvestmentProduct = Parse.Object.extend('InvestmentProduct');
    const investmentProduct = new InvestmentProduct();
    investmentProduct.id = data.id;

    investmentProduct.set('totalAssets', data.totalAssets);
    investmentProduct.set('totalIncome', data.totalIncome);
    investmentProduct.set('totalExpense', data.totalExpense);
    investmentProduct.set('totalShares', data.totalShares);

    const result = await investmentProduct.save();

    return true;

  } catch (error) {
    console.error('updateGeneralConfig', error);
    return false;
  }

}

export default updateGeneralConfig;
