export default {
  ROOT: '/',
  CUSTOMERS: '/customers',
  ADD_CUSTOMER: '/customers/add',
  CUSTOMER_DETAILS: '/customer',
  HORSES: '/horses',
  ADD_HORSE: '/horses/add',
  HORSE_DETAILS: '/horse',
  SYNDICATION: '/syndication',
  INVESTMENT: '/investment',
  INVESTMENT_HORSE_SHARES: '/investment-horse-shares',
  MARKET_OVERVIEW: '/market-overview',
  COMPANY_TRANSACTIONS: '/company-transactions',
  LOGIN: '/login',
}
