import React, { useContext, useEffect, useState } from 'react';

import { RouteContext } from '../../App';

import {
  deleteMarketData,
  fetchMarketsData,
  saveMarketData,
} from './actions';


import MarketOverview from './MarketOverview';

const MarketOverviewContainer = () => {

  const { setCurrentRoute } = useContext(RouteContext);

  const [ isLoading, setIsLoading ] = useState(true);
  const [ isSaving, setIsSaving ] = useState(false);

  // Table states
  const [ weanlingData, setWeanlingData ] = useState([]);
  const [ yearlingData, setYearlingData ] = useState([]);
  const [ rtrData, setRtrData ] = useState([]);
  const [ broodmareData, setBroodmareData ] = useState([]);
  const [ foalData, setFoalData ] = useState([]);
  const [ bettingData, setBettingData ] = useState([]);
  const [ prizeMoneyData, setPrizeMoneyData ] = useState([]);


  useEffect(() => {
    setCurrentRoute('market-overview');
    _initData();
  }, []);

  const _initData = async () => {
    const result = await fetchMarketsData();
    _splitRecords(result);
    setIsLoading(false);
  };

  const _splitRecords = (records) => {

    const recordsMap = {
      weanling: [],
      yearling: [],
      rtr: [],
      broodmare: [],
      foal: [],
      betting: [],
      prizeMoney: [],
    }

    records.forEach((record) => {

      const flattenRecord = {
        id: record.id,
        year: record.year,
        type: record.type,
        ...record.data,
      }

      recordsMap[record.type].push(flattenRecord);
    });

    setWeanlingData(recordsMap.weanling);
    setYearlingData(recordsMap.yearling);
    setRtrData(recordsMap.rtr);
    setBroodmareData(recordsMap.broodmare);
    setFoalData(recordsMap.foal);
    setBettingData(recordsMap.betting);
    setPrizeMoneyData(recordsMap.prizeMoney);
  }

  const handleAddData = async (data) => {
    setIsSaving(true);
    await _saveData(data);
    setIsSaving(false);
  }

  const handleUpdateData = async (data) => {

    let objectData = {
      id: data.id,
      type: data.type,
      year: data.year,
      data: {},
    }

    const type = data.type;

    if (['weanling', 'yearling', 'rtr', 'broodmare'].indexOf(type) >= 0) {
      objectData.data = {
        nzb: data.nzb,
        mm: data.mm,
        ing: data.ing,
      }
    } else if (type === 'foal') {
      objectData.data = {
        au: data.au,
        nz: data.nz,
        jp: data.jp,
      }
    } else if (['betting', 'prizeMoney'].indexOf(type) >= 0) {
      objectData.data = {
        au: data.au,
        nz: data.nz,
        sg: data.sg,
        jp: data.jp,
        hk: data.hk,
      }
    }

    await _saveData(objectData, 'update');
  }

  const _saveData = async (data, method) => {
    const result = await saveMarketData(data, method);
    _splitRecords(result);
  }

  const handleDeleteData = async (data) => {
    const result = await deleteMarketData(data);
    _splitRecords(result);
  }

  return(
    <MarketOverview
      bettingData={bettingData}
      broodmareData={broodmareData}
      foalData={foalData}
      isLoading={isLoading}
      isSaving={isSaving}
      onAddData={handleAddData}
      onDeleteData={handleDeleteData}
      onUpdateData={handleUpdateData}
      prizeMoneyData={prizeMoneyData}
      rtrData={rtrData}
      weanlingData={weanlingData}
      yearlingData={yearlingData}
    />
  );
}

export default MarketOverviewContainer;
