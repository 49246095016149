
import React from 'react';

import {
  Grid,
} from '@material-ui/core';

import {
  TransactionsTable,
} from './components';

import { Spinner, Title } from '../../components';

const CompanyTransactions = ({
  expenseTransaction,
  incomeTransaction,
  isLoading,
  isSaving,
  onAddData,
  onDeleteData,
  onUpdateData,
  totalExpense,
  totalIncome,
}) => {

  return (
    <>
      {isLoading ?
        <Grid item xs={12}>
          <Spinner />
        </Grid> :
        <>
          <Grid
            item
            xs={12}
          >
            <Title>Company Transactions</Title>
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
          >
            <TransactionsTable
              data={incomeTransaction}
              isSaving={isSaving}
              onAddData={onAddData}
              onDeleteData={onDeleteData}
              onUpdateData={onUpdateData}
              title="Income"
              totalSum={totalIncome}
              type="income"
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
          >
            <TransactionsTable
              data={expenseTransaction}
              isSaving={isSaving}
              onAddData={onAddData}
              onDeleteData={onDeleteData}
              onUpdateData={onUpdateData}
              title="Expense"
              totalSum={totalExpense}
              type="expense"
            />
          </Grid>
        </>
      }
    </>
  );
}

export default CompanyTransactions;
