import Parse from 'parse';

async function deletePortalUserAction(id) {
  try {

    const userQuery = new Parse.Query('User');
    const user = await userQuery.get(id);

    if (!user) return false;

    const result = user.destroy();

    return true;

  } catch (error) {
    console.error('updatePortalUserAction error', error);
    return false;
  }
}

export default deletePortalUserAction;
