import React from 'react';

import Grid from '@material-ui/core/Grid';

import { DataCard, Spinner, Title } from '../../components';
import { routes } from '../../const';

import { OwnerTransactions, SyndicationShares } from './components';

const DataCardGrid = ({
  format,
  linkURL,
  title,
  value,
}) => (
  <Grid
    item
    xs={3}
  >
    <DataCard
      format={format}
      linkURL={linkURL}
      title={title}
      value={value}
    />
  </Grid>
);

// eslint-disable-next-line react/no-multi-comp
const Syndication = ({
  isLoading,
  dataSyndication,
  dataTransactions,
  dataShares,
  isSaving,
  onShareCreate,
  onShareDelete,
  onShareUpdate,
  onTransactionCreate,
  onTransactionDelete,
  onTransactionUpdate,
}) => {

  const {
    currentShare,
    customer,
    customerId,
    depositBalance,
    frozeDepositUntil,
    horse,
    horseId,
    incomeBalance,
    totalBalance,
    totalPrizeMoneyBalance,
  } = dataSyndication;

  return (
    <>
      {isLoading ?
        <Grid item xs={12}>
          <Spinner />
        </Grid> :
        <>
          {/* Page Title */}
          <Grid item xs={12}>
            <Title>Syndication Details</Title>
          </Grid>

          <DataCardGrid
            linkURL={`${routes.CUSTOMER_DETAILS}/${customerId}`}
            title={'Customer'}
            value={customer}
          />
          <DataCardGrid
            linkURL={`${routes.HORSE_DETAILS}/${horseId}`}
            title={'Horse'}
            value={horse}
          />
          <DataCardGrid
            format="percentage"
            title={'Current Share'}
            value={currentShare}
          />
          <DataCardGrid
            format="date"
            title={'Froze Deposit Until'}
            value={frozeDepositUntil}
          />
          <DataCardGrid
            format="currency"
            title={'Total Balance'}
            value={totalBalance}
          />
          <DataCardGrid
            format="currency"
            title={'Deposit Balance'}
            value={depositBalance}
          />
          <DataCardGrid
            format="currency"
            title={'Income Balance'}
            value={incomeBalance}
          />
          <DataCardGrid
            format="currency"
            title={'Total Prize Money'}
            value={totalPrizeMoneyBalance}
          />

          {/* Transaction and shares */}
          <Grid
            item
            xs={12}
          >
            <SyndicationShares
              data={dataShares}
              isSaving={isSaving}
              onCreate={onShareCreate}
              onDelete={onShareDelete}
              onUpdate={onShareUpdate}
            />
          </Grid>
          <Grid
            item
            xs={12}>
            <OwnerTransactions
              data={dataTransactions}
              isSaving={isSaving}
              onCreate={onTransactionCreate}
              onDelete={onTransactionDelete}
              onUpdate={onTransactionUpdate}
            />;
          </Grid>
        </>
      }
    </>
  );
}

export default Syndication;
