import React from 'react';

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from '@material-ui/core/';

import {
  FormGridItem
} from './components';

import { ButtonWithLoading, Spinner } from '../../../../components';

const GeneralConfig = ({
  investmentProduct,
  isLoading,
  isSubmitting,
  onFieldChange,
  onSubmit,
}) => {

  const {
    totalAssets,
    totalIncome,
    totalExpense,
    totalShares,
    nextDividendDate,
  } = investmentProduct;

  const error = false;

  return (
    <Card>
      <CardHeader
        title="General Config"
      />
      <Divider />
      <CardContent>

        {isLoading ?
          <Spinner /> :
          <>
            <Grid
              container
              spacing={3}
            >
              <FormGridItem>
                <TextField
                  disabled={isSubmitting}
                  error={error}
                  fullWidth
                  helperText={error ? 'This is a required field' : null}
                  label="Total Share"
                  name="totalShares"
                  onChange={onFieldChange}
                  required
                  type="number"
                  value={totalShares}
                />
              </FormGridItem>
              <FormGridItem>
                <TextField
                  disabled
                  error={error}
                  fullWidth
                  helperText={error ? 'This is a required field' : null}
                  label="Total Income"
                  name="totalIncome"
                  onChange={onFieldChange}
                  required
                  type="number"
                  value={totalIncome}
                />
              </FormGridItem>
              <FormGridItem>
                <TextField
                  disabled
                  error={error}
                  fullWidth
                  helperText={error ? 'This is a required field' : null}
                  label="Total Expense"
                  name="totalExpense"
                  onChange={onFieldChange}
                  required
                  type="number"
                  value={totalExpense}
                />
              </FormGridItem>
              <FormGridItem>
                <TextField
                  disabled
                  fullWidth
                  label="Total Assets"
                  name="totalAssets"
                  type="number"
                  value={totalAssets}
                />
              </FormGridItem>
              <FormGridItem>
                <TextField
                  disabled
                  fullWidth
                  label="Next Dividend Date"
                  name="nextDividendDate"
                  value={nextDividendDate.toLocaleDateString()}
                />
              </FormGridItem>
              <FormGridItem>
                <ButtonWithLoading
                  color="primary"
                  defaultLabel="Save Changes"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  loadingLabel="Saving..."
                  onClick={onSubmit}
                  variant="contained"
                />
              </FormGridItem>
            </Grid>
          </>
        }

      </CardContent>
    </Card>
  );
};

export default GeneralConfig;
