import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import currency from 'currency.js';

import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { formatDate } from '../../utils';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    padding: theme.spacing(2),
  },
  creditAmount: {
    color: '#e57373',
  },
  debitAmount: {
    color: '#4caf50',
  }
}));

const getDisplayValue = (value, format) => {

  if (!format) return value;

  switch (format) {
    case 'currency':
      return `${currency(value).format()}`;
    case 'date':
      return formatDate(value);
    case 'percentage':
      return `${value}%`;
    default:
      return value;
  }
}

const DataCard = ({
  format,
  linkURL,
  title,
  value
}) => {

  const classes = useStyles();

  const displayValue = getDisplayValue(value, format);

  const getCurrencyClasses = () => {
    if (format !== 'currency' || !value) return;
    return value > 0 ? classes.debitAmount : classes.creditAmount;
  }

  return (
    <Paper className={classes.paper}>
      <Typography
        color="textSecondary"
        component="p"
        variant="overline"
      >
        {title}
      </Typography>
      <Typography
        className={getCurrencyClasses()}
        component="p"
        variant="h6"
      >
        {linkURL ?
          <Link component={RouterLink} to={linkURL}>{displayValue}</Link> :
          displayValue
        }
      </Typography>
    </Paper>
  );
}

export default DataCard;
