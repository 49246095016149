import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import MaterialTable from 'material-table';

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from '@material-ui/core';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import {
  ButtonWithLoading,
} from '../../../../components';

const OwnerTransactions = ({
  data,
  isSaving,
  onCreate,
  onDelete,
  onUpdate,
}) => {

  const [ newData, setNewData ] = useState({
    startDate: new Date(),
    sharePercentage: 0,
  });

  const handleFieldChange = (name, value) => {
    setNewData({
      ...newData,
      [name]: value
    });
  }

  const handleClick = () => onCreate(newData);

  const columns = [
    { title: 'Start Date', field: 'startDate', type: 'date' },
    { title: 'Share', field: 'sharePercentage', type: 'numeric', align: 'left' },
  ];

  return (
    <Card>
      <CardHeader title="Syndication Shares" />
      <Divider />
      <CardContent style={{ padding: 0, paddingBottom: 0 }}>

        {/* Add data panel */}
        <div style={{ padding: 16 }}>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              xs={6}
              md={4}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disabled={isSaving}
                  disableToolbar
                  format="dd/MM/yyyy"
                  fullWidth
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  label="Start Date"
                  name="startDate"
                  onChange={(dateString) => handleFieldChange('startDate', dateString)}
                  value={newData.startDate}
                  variant="inline"
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid
              item
              xs={6}
              md={4}
            >
              <TextField
                disabled={isSaving}
                fullWidth
                label="Share Percentage"
                name="sharePercentage"
                onChange={(event) => handleFieldChange('sharePercentage', event.target.value)}
                type="number"
                value={newData.sharePercentage}
              />
            </Grid>
            <Grid
              item
              xs={6}
              md={4}
            >
              <ButtonWithLoading
                color="primary"
                defaultLabel="Add Data"
                disabled={isSaving}
                isLoading={isSaving}
                loadingLabel="Adding..."
                onClick={handleClick}
                variant="contained"
              />
            </Grid>
          </Grid>
        </div>

        <Divider />

        {/* Table */}
        <MaterialTable
          columns={columns}
          components={{
            Container: props => <div>{props.children}</div>,
          }}
          data={data}
          editable={{
            onRowDelete: onDelete,
            onRowUpdate: onUpdate,
          }}
          options={{
            actionsColumnIndex: -1,
            search: false,
            paging: false,
            draggable: false,
            showTitle: false,
            toolbar: false,
          }}
        />

      </CardContent>
    </Card>
  );
}

export default OwnerTransactions;
