import Parse from 'parse';

/**
 * Fetch Investment Product
 * @returns {object}
 */
const fetchInvestmentProduct = async() => {
  try {
    const productQuery = new Parse.Query('InvestmentProduct');
    const statementQuery = new Parse.Query('CompanyStatement');
    statementQuery.descending('statementDate');

    const [
      investmentProduct,
      statement,
      nextDividendDate,
    ] = await Promise.all([
      productQuery.first(),
      statementQuery.first(),
      Parse.Cloud.run('fetchNextDividendDate'),
    ]);

    return {
      nextDividendDate,
      id: investmentProduct.id,
      totalAssets: statement.get('totalAssets'),
      totalIncome: investmentProduct.get('totalIncome'),
      totalExpense: investmentProduct.get('totalExpense'),
      totalShares: investmentProduct.get('totalShares'),
    }

  } catch (error) {
    console.error('fetchInvestmentProduct error', error);
  }
}

export default fetchInvestmentProduct;
