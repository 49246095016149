import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
    color: green[500],
  },
  buttonWrapper: {
    display: 'inline-block',
    position: 'relative',
  },
  buttonFullWidth: {
    display: 'block',
  }
}));

const ButtonWithLoading = ({
  color,
  className,
  defaultLabel,
  disabled,
  fullWidth,
  loadingLabel,
  isLoading,
  onClick,
  variant,
}) => {

  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.buttonWrapper,
        fullWidth ? classes.buttonFullWidth : null,
        className ? className : null,
      )}
    >
      <Button
        color={color || 'primary'}
        disabled={isLoading || disabled}
        fullWidth
        onClick={onClick}
        variant={variant || 'contained'}
      >
        {isLoading ? loadingLabel : defaultLabel}
      </Button>
      {isLoading &&
        <CircularProgress
          className={classes.buttonProgress}
          size={24}
        />}
    </div>
  );
}

export default ButtonWithLoading;
