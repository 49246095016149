
import React from 'react';

import {
  Grid,
} from '@material-ui/core';

import {
  AuctionTable,
  FiveCountriesTable,
  ThreeCountriesTable,
} from './components';

import { Spinner, Title } from '../../components';

const MarketOverview = ({
  bettingData,
  broodmareData,
  foalData,
  isLoading,
  isSaving,
  onAddData,
  onDeleteData,
  onUpdateData,
  prizeMoneyData,
  rtrData,
  weanlingData,
  yearlingData,
}) => {

  return (
    <>
      {isLoading ?
        <Grid item xs={12}>
          <Spinner />
        </Grid> :
        <>
          <Grid
            item
            xs={12}
          >
            <Title>Marketing Overview</Title>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <AuctionTable
              data={weanlingData}
              isSaving={isSaving}
              onAddData={onAddData}
              onDeleteData={onDeleteData}
              onUpdateData={onUpdateData}
              title="Weanling"
              type="weanling"
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <AuctionTable
              data={yearlingData}
              isSaving={isSaving}
              onAddData={onAddData}
              onDeleteData={onDeleteData}
              onUpdateData={onUpdateData}
              title="Yearling"
              type="yearling"
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <AuctionTable
              data={rtrData}
              isSaving={isSaving}
              onAddData={onAddData}
              onDeleteData={onDeleteData}
              onUpdateData={onUpdateData}
              title="2-Y-O(RTR)"
              type="rtr"
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <AuctionTable
              data={broodmareData}
              isSaving={isSaving}
              onAddData={onAddData}
              onDeleteData={onDeleteData}
              onUpdateData={onUpdateData}
              title="Broodmare"
              type="broodmare"
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <ThreeCountriesTable
              data={foalData}
              isSaving={isSaving}
              onAddData={onAddData}
              onDeleteData={onDeleteData}
              onUpdateData={onUpdateData}
              title="Foal"
              type="foal"
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <FiveCountriesTable
              data={bettingData}
              isSaving={isSaving}
              onAddData={onAddData}
              onDeleteData={onDeleteData}
              onUpdateData={onUpdateData}
              title="Betting"
              type="betting"
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <FiveCountriesTable
              data={prizeMoneyData}
              isSaving={isSaving}
              onAddData={onAddData}
              onDeleteData={onDeleteData}
              onUpdateData={onUpdateData}
              title="Prize Money"
              type="prizeMoney"
            />
          </Grid>
        </>
      }
    </>
  );
}

export default MarketOverview;
