import Parse from 'parse';
import { fetchInvestmentHorses } from '../actions';

// Error Messages
const ERROR_INVALID_DATA = 'Horse Id, Investment Product Id, Shares, and Start Date must be supplied';

/**
 * Create Investment Horse
 * @description
 * 1. Create new Investment Horse record with horseId, sharePercentage
 * 2. Create new Investment Horse Share record with investmentHorse, startDate and sharePercentage
 * 3. Return all Investment Horse Records
 * @param {object} data
 * @param {string} data.horseId - Required for create
 * @param {string} data.investmentProductId - Required for create
 * @param {number} data.shares - Required for create
 * @param {date} data.startDate - Required for create
 * @return {object[]}
 */
const createInvestmentHorse = async (data) => {
  try {

    if (!data || !data.horseId || !data.investmentProductId || !data.shares && data.shares !== 0 || !data.startDate) {
      throw new Error(ERROR_INVALID_DATA);
    }

    // Create new Investment Horse record
    const horse = new (Parse.Object.extend('Horse'))();
    horse.id = data.horseId;

    const investmentProduct = new (Parse.Object.extend('InvestmentProduct'))();
    investmentProduct.id = data.investmentProductId;

    const investmentHorse = new (Parse.Object.extend('InvestmentHorse'))();
    investmentHorse.set('horse', horse);
    investmentHorse.set('investmentProduct', investmentProduct);
    investmentHorse.set('shares', data.shares);
    const investmentHorseResponse = await investmentHorse.save();

    // Create new Investment Horse Share record
    const investmentHorseShare = new (Parse.Object.extend('InvestmentHorseShare'))();
    investmentHorseShare.set('investmentHorse', investmentHorseResponse);
    investmentHorseShare.set('sharePercentage', data.shares);
    investmentHorseShare.set('startDate', data.startDate);
    await investmentHorseShare.save();

    // Get all Investment Horse Records
    const payload = await fetchInvestmentHorses();
    return payload;

  } catch (error) {
    console.error('createInvestmentHorse error', error);
    return [];
  }

}

export default createInvestmentHorse;
