import Parse from 'parse';

async function fetchHorseDetails(horseId) {
  try {
    const Horse = Parse.Object.extend('Horse');
    const query = new Parse.Query(Horse);
    query.equalTo('objectId', horseId);
    const response = await query.first();

    const syndicationQuery = new Parse.Query('Syndication');
    syndicationQuery.equalTo('horse', response);
    const syndication = await syndicationQuery.first();

    const payload = {
      id: response.id,
      horseName: response.get('horseName'),
      horseType: response.get('horseType'),
      serialNumber: response.get('serialNumber'),
      gender: response.get('gender'),
      dateOfBirth: response.get('dateOfBirth'),
      breedingStud: response.get('breedingStud'),
      coatColor: response.get('coatColor'),
      sire: response.get('sire'),
      dam: response.get('dam'),
      trainer: response.get('trainer'),
      countryOfOrigin: response.get('countryOfOrigin'),
      trainingPlace: response.get('trainingPlace'),
      presentStatus: response.get('presentStatus'),
      pedigreeUrl: response.get('pedigreeUrl'),
      image: response.get('image'),
      horseValue: response.get('horseValue'),
      hasSyndication: syndication !== null && syndication !== undefined
    };

    return payload;

  } catch (error) {
    console.error('fetchHorseDetails error', error);
  }

}

export default fetchHorseDetails;
