import React, { useContext, useEffect, useState } from 'react';

import Horses from './Horses';

import { fetchHorses } from './actions';

import { RouteContext } from '../../App';

import { DataProvider } from '../../utils/DataContext';

export default function HorsesContainer() {

  const { setCurrentRoute } = useContext(RouteContext);

  useEffect(() => {
    setCurrentRoute('horses');
  }, []);

  const [horses, setHorses] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true);

  async function fetchData() {
    const fetchedHorses = await fetchHorses();
    setHorses(fetchedHorses);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <DataProvider value={horses}>
      <Horses isLoading={isLoading} />
    </DataProvider>
  );
}
