/* eslint-disable react/no-multi-comp */
import React from 'react';

import MaterialTable from 'material-table';

const MarketTable = ({
  columns,
  data,
  onDeleteData,
  onUpdateData,
}) => {

  return (
    <MaterialTable
      columns={columns}
      components={{
        Container: props => <div>{props.children}</div>,
      }}
      data={data}
      editable={{
        onRowDelete: onDeleteData,
        onRowUpdate: onUpdateData,
      }}
      options={{
        actionsColumnIndex: -1,
        search: false,
        paging: false,
        draggable: false,
        showTitle: false,
        toolbar: false,
      }}
    />
  );
}

export default MarketTable;
