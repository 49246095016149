import Parse from 'parse';

async function fetchMarketsData() {
  try {
    const query = new Parse.Query('Market');
    query.ascending('year');
    const response = await query.find();

    const payload = response.map((data) => ({
      id: data.id,
      year: data.get('year'),
      type: data.get('type'),
      data: data.get('data'),
    }));

    return payload;

  } catch (error) {
    console.error('fetchMarketsData error', error);
    return [];
  }
}

export default fetchMarketsData;
