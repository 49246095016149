import React, { useState } from 'react';

import AddHorse from './AddHorse';

import { saveHorse } from './actions';

import { DataProvider } from '../../utils/DataContext';

const initialHorseState = {
  horseName: '',
  serialNumber: '',
  gender: 'Filly',
  dateOfBirth: new Date(),
  breedingStud: '',
  coatColor: 'Bay',
  horseType: 'Race Horse',
  sire: '',
  dam: '',
  trainer: '',
  countryOfOrigin: 'New Zealand',
  trainingPlace: 'New Zealand',
  presentStatus: '',
  pedigreeUrl: '',
  image: ''
}

const AddHorseContainer = () => {

  const [horse, setHorse] = useState(initialHorseState);
  const [isLoading, setIsLoading] = useState(false);

  const handleFieldChange = (event) => {
    const newState = {
      ...horse,
      [event.target.name]: event.target.value
    }
    setHorse(newState);
  }

  const handleDateFieldChange = (dateString) => {
    const newState = {
      ...horse,
      dateOfBirth: dateString
    }
    setHorse(newState);
  }

  const handleImageChange = (image) => {
    const newState = {
      ...horse,
      image: image
    }
    setHorse(newState);
  }

  const handleSubmit = () => {
    setIsLoading(true);
    saveHorse(horse);
  }

  const addHorseContext = {
    horse,
    handleDateFieldChange,
    handleFieldChange,
    handleImageChange,
    handleSubmit,
    isLoading,
  }

  return(
    <DataProvider value={addHorseContext}>
      <AddHorse />
    </DataProvider>
  );
}

export default AddHorseContainer;
