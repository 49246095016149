import Parse from 'parse';
import { fetchHorseTransactions } from './index';

/**
 * Save owner transaction
 * @param {object} data
 * @param {string} method - create | update | delete
 */
const saveHorseTransaction = async (data, method = 'create') => {
  try {

    const HorseTransaction = Parse.Object.extend('HorseTransaction');
    const transaction = new HorseTransaction();

    // Point to syndication if is create
    if (method === 'create') {
      const Horse = Parse.Object.extend('Horse');
      const horse = new Horse();
      horse.id = data.horseId;
      transaction.set('horse', horse);
    }

    // Add transaction id if is update
    if ((method === 'update' || method === 'delete') && data.id) {
      transaction.id = data.id;
    }

    if (method === 'delete') {
      // Delete transaction data
      await transaction.destroy();
    } else {

      transaction.set('date', new Date(data.date));
      transaction.set('type', data.type);
      transaction.set('description', data.description);
      transaction.set('amount', parseFloat(data.amount));
      transaction.set('invoiceURL', data.invoiceURL);
      transaction.set('companyInvoiceUrl', data.companyInvoiceUrl);

      // Save transaction data
      await transaction.save();
    }

    // Get updated syndication data
    const payload = await fetchHorseTransactions(data.horseId);

    return payload;

  } catch (error) {
    console.error('saveSyndicationShare error', error);
    return [];
  }

}

export default saveHorseTransaction;
