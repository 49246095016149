import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';

import {
  ExitToApp as ExitToAppIcon,
  Layers as LayersIcon,
  People as PeopleIcon,
} from '@material-ui/icons';

import { routes } from '../../../../../../const';
import { HorseIcon } from '../../../../../../components';

import { RouteContext } from '../../../../../../App';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(6),
  },
}));

const SidebarNav = ({ handleDrawerToggle, onLogout }) => {

  const classes = useStyles();

  const { currentRoute } = useContext(RouteContext);

  return (
    <List>
      <ListItem
        button
        component={RouterLink}
        onClick={handleDrawerToggle}
        selected={currentRoute === 'customers'}
        to={routes.CUSTOMERS}
      >
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Customers" />
      </ListItem>
      <ListItem
        button
        component={RouterLink}
        onClick={handleDrawerToggle}
        selected={currentRoute === 'horses'}
        to={routes.HORSES}
      >
        <ListItemIcon>
          <HorseIcon />
        </ListItemIcon>
        <ListItemText primary="Horses" />
      </ListItem>
      <ListItem
        button
        component={RouterLink}
        onClick={handleDrawerToggle}
        selected={currentRoute === 'investment' || currentRoute === 'market-overview' || currentRoute === 'company-transactions'}
        to={routes.INVESTMENT}
      >
        <ListItemIcon>
          <LayersIcon />
        </ListItemIcon>
        <ListItemText primary="Investment" />
      </ListItem>

      <List component="div" disablePadding>
        <ListItem
          button
          className={classes.nested}
          component={RouterLink}
          selected={currentRoute === 'investment'}
          to={routes.INVESTMENT}
        >
          <ListItemText primary="General" />
        </ListItem>
      </List>

      <List component="div" disablePadding>
        <ListItem
          button
          className={classes.nested}
          component={RouterLink}
          selected={currentRoute === 'market-overview'}
          to={routes.MARKET_OVERVIEW}
        >
          <ListItemText primary="Market Overview" />
        </ListItem>
      </List>

      <List component="div" disablePadding>
        <ListItem
          button
          className={classes.nested}
          component={RouterLink}
          selected={currentRoute === 'company-transactions'}
          to={routes.COMPANY_TRANSACTIONS}
        >
          <ListItemText primary="Company Transactions" />
        </ListItem>
      </List>

      <ListItem
        button
        onClick={onLogout}
      >
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText
          primary="Logout"
        />
      </ListItem>
    </List>
  );
}

export default SidebarNav;
