import Parse from 'parse';

async function fetchHorses() {
  const Horse = Parse.Object.extend('Horse');
  const query = new Parse.Query(Horse);
  // query.equalTo("myCustomKey1Name", "myCustomKey1Value");
  const response = await query.find();

  const payload = await response.map((horse) => ({
    id: horse.id,
    name: horse.get('horseName')
  }));

  return payload;
}

export default fetchHorses;
