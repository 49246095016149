import React, { useContext, useEffect, useState } from 'react';

import { RouteContext } from '../../App';

import {
  fetchCompanyTransactions,
  saveCompanyTransaction,
} from './actions';


import CompanyTransactions from './CompanyTransactions';

const CompanyTransactionsContainer = () => {

  const { setCurrentRoute } = useContext(RouteContext);

  const [ isLoading, setIsLoading ] = useState(true);
  const [ isSaving, setIsSaving ] = useState(false);

  // Table states
  const [ incomeTransaction, setIncomeTransaction ] = useState([]);
  const [ expenseTransaction, setExpenseTransaction ] = useState([]);

  // Total balances
  const [ totalIncome, setTotalIncome ] = useState(0);
  const [ totalExpense, setTotalExpense ] = useState(0);

  useEffect(() => {
    setCurrentRoute('company-transactions');
    _initData();
  }, []);

  const _initData = async () => {
    const result = await fetchCompanyTransactions();
    _updateTransactionStates(result);
    setIsLoading(false);
  };

  const _updateTransactionStates = (response) => {
    setIncomeTransaction(response.transactions.income);
    setExpenseTransaction(response.transactions.expense);
    setTotalIncome(response.sums.income);
    setTotalExpense(response.sums.expense);
  }

  const handleAddData = async (data) => {
    setIsSaving(true);
    await _saveData(data);
    setIsSaving(false);
  }

  const handleUpdateData = async (data) => {
    await _saveData(data, 'update');
  }

  const handleDeleteData = async (data) => {
    await _saveData(data, 'delete');
  }

  const _saveData = async (data, method) => {
    const result = await saveCompanyTransaction(data, method);
    _updateTransactionStates(result);
  }

  return(
    <CompanyTransactions
      expenseTransaction={expenseTransaction}
      incomeTransaction={incomeTransaction}
      isLoading={isLoading}
      isSaving={isSaving}
      onAddData={handleAddData}
      onDeleteData={handleDeleteData}
      onUpdateData={handleUpdateData}
      totalExpense={totalExpense}
      totalIncome={totalIncome}
    />
  );
}

export default CompanyTransactionsContainer;
