import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import HorseShares from './HorseShares';

import {
  Spinner,
} from '../../../../components';

import {
  fetchSyndicationsInitData,
  saveSyndication,
} from './actions';

const HorseSharesContainer = () => {

  const { customerId } = useParams();

  const [ dataSyndications, setDataSyndications ] = useState([]);
  const [ dataHorses, setDataHorses ] = useState([]);

  // Loading states
  const [ isLoading, setIsLoading ] = useState(true);
  const [ isSaving, setIsSaving ] = useState(false);

  useEffect(() => {
    _initData();
  }, []);

  const _initData = async () => {
    const result = await fetchSyndicationsInitData(customerId);
    _splitRecords(result);
    setIsLoading(false);
  }

  const _splitRecords = (records) => {
    setDataHorses(records.horses);
    setDataSyndications(records.syndications);
  }

  // Syndication CURD
  const handleSyndicationCreate = async (data) => {
    setIsSaving(true);
    await _saveSyndication(data);
    setIsSaving(false);
  }

  const handleSyndicationDelete = async (data) => {
    await _saveSyndication(data, 'delete');
  }

  const _saveSyndication = async (data, method) => {
    const result = await saveSyndication({ ...data, customerId }, method);
    _splitRecords(result);
  }

  return (
    isLoading ?
      <Spinner /> :
      <HorseShares
        dataHorses={dataHorses}
        dataSyndications={dataSyndications}
        isLoading={isLoading}
        isSaving={isSaving}
        onCreate={handleSyndicationCreate}
        onDelete={handleSyndicationDelete}
      />
  );
}

export default HorseSharesContainer;
