import Parse from 'parse';

async function fetchCustomers() {
  try {
    const query = new Parse.Query('Customer');
    const response = await query.find();

    const payload = response.map((customer) => ({
      id: customer.id,
      customerName: customer.get('firstName') + ' ' + customer.get('lastName'),
    }));

    return payload;

  } catch (error) {
    console.error('fetchHorses error', error);
    return [];
  }
}

export default fetchCustomers;
