import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3),
  },
  formGroup: {
    marginBottom: theme.spacing(4),
  },
  formActions: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  }
}));
