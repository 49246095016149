import Parse from 'parse';

async function fetchHorseTransactions(horseId) {
  try {
    const Horse = Parse.Object.extend('Horse');
    const horse = new Horse();
    horse.id = horseId;

    const query = new Parse.Query('HorseTransaction');
    query.equalTo('horse', horse);
    query.descending('date');

    const response = await query.find();

    const payload = response.map((transaction) => ({
      id: transaction.id,
      date: transaction.get('date'),
      type: transaction.get('type'),
      description: transaction.get('description'),
      amount: transaction.get('amount'),
      invoiceURL: transaction.get('invoiceURL'),
      companyInvoiceUrl: transaction.get('companyInvoiceUrl'),
    }));

    return payload;


  } catch (error) {
    console.error('fetchHorseTransactions error', error);

    // Return empty array on error
    return [];
  }

}

export default fetchHorseTransactions;
