import React, { useContext, useEffect, useState } from 'react';

import Customers from './Customers';

import { fetchCustomers } from './actions';

import { RouteContext } from '../../App';

import { DataProvider } from '../../utils/DataContext';

export default function CustomersContainer() {

  const { setCurrentRoute } = useContext(RouteContext);

  useEffect(() => {
    setCurrentRoute('customers');
  }, []);

  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  async function fetchData() {
    const customers = await fetchCustomers();
    setCustomers(customers);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <DataProvider value={customers}>
      <Customers isLoading={isLoading} />
    </DataProvider>
  );
}
