/* eslint-disable react/no-multi-comp */
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import DateFnsUtils from '@date-io/date-fns';

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Link,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';

import SaveAltIcon from '@material-ui/icons/SaveAlt';

import MaterialTable from 'material-table';

import currency from 'currency.js';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import {
  ButtonWithLoading,
  Spinner,
} from '../../../../components';

import { routes } from '../../../../const';

const useStyles = makeStyles({
  action: {
    alignSelf: 'center',
    marginTop: 0,
  },
});

const TransactionsTable = ({
  data,
  isLoading,
  isSaving,
  onAddData,
  onDeleteData,
  onUpdateData,
  title,
  totalSum,
  type,
}) => {

  const classes = useStyles();

  const columns = [
    {
      title: 'Date',
      field: 'date',
      type: 'date',
    },
    {
      title: 'Horse',
      field: 'horseName',
      editable: 'never',
      render: ({ horseId, horseName, sharePercentage }) => {
        let name = horseName;
        if (sharePercentage) name = horseName + ` (${sharePercentage}%)`;
        return <>
          {(horseId && horseName) ?
            <Link
              component={RouterLink}
              to={`${routes.HORSE_DETAILS}/${horseId}`}
            >
              {name}
            </Link> : null
          }
        </>
      },
    },
    {
      title: 'Amount',
      field: 'amount',
      type: 'currency',
      align: 'left',
    },
    {
      title: 'Description',
      field: 'description',
    },
    {
      title: 'Invoice',
      field: 'invoiceUrl',
      cellStyle: {paddingTop: 0, paddingBottom: 0,},
      render: ({ invoiceUrl }) => (
        <>
          {invoiceUrl ?
            <IconButton
              component={Link}
              href={invoiceUrl}
              rel="noopener"
              target="_blank"
            >
              <SaveAltIcon />
            </IconButton> :
            null
          }
        </>
      )
    },
  ];

  const [ newData, setNewData ] = useState({
    date: new Date(),
    type: type,
    amount: 0,
    description: '',
    invoiceUrl: '',
  });

  const handleFieldChange = (name, value) => {
    setNewData({
      ...newData,
      [name]: value
    });
  }

  const handleClick = () => onAddData(newData);

  return (
    <>
      <Card>
        <CardHeader
          action={<Typography>Total: {currency(totalSum).format()}</Typography>}
          classes={{
            action: classes.action,
          }}
          title={title}
        />
        <Divider />
        {isLoading ?
          <Spinner /> :
          <CardContent style={{ padding: 0, paddingBottom: 0 }}>

            {/* Add data panel */}
            <div style={{ padding: 16 }}>
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  xs={6}
                  md={2}
                  lg={6}
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disabled={isSaving}
                      disableToolbar
                      format="dd/MM/yyyy"
                      fullWidth
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      label="Date"
                      name="date"
                      onChange={(dateString) => handleFieldChange('date', dateString)}
                      value={newData.date}
                      variant="inline"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={2}
                  lg={6}
                >
                  <TextField
                    disabled={isSaving}
                    fullWidth
                    label="Amount"
                    name="amount"
                    onChange={(event) => handleFieldChange('amount', event.target.value)}
                    type="number"
                    value={newData.amount}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={3}
                  lg={4}
                >
                  <TextField
                    disabled={isSaving}
                    fullWidth
                    label="Description"
                    name="description"
                    onChange={(event) => handleFieldChange('description', event.target.value)}
                    value={newData.description}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={3}
                  lg={4}
                >
                  <TextField
                    disabled={isSaving}
                    fullWidth
                    label="Invoice"
                    name="invoiceUrl"
                    onChange={(event) => handleFieldChange('invoiceUrl', event.target.value)}
                    value={newData.invoiceUrl}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={2}
                  lg={4}
                >
                  <ButtonWithLoading
                    color="primary"
                    defaultLabel="Add Data"
                    disabled={isSaving}
                    isLoading={isSaving}
                    loadingLabel="Adding..."
                    onClick={handleClick}
                    variant="contained"
                  />
                </Grid>
              </Grid>
            </div>

            <Divider />

            {/* Table */}
            <MaterialTable
              columns={columns}
              components={{
                Container: props => <div>{props.children}</div>,
              }}
              data={data}
              editable={{
                isEditable: (rowData) => !rowData.horseId,
                isDeletable: (rowData) => !rowData.horseId,
                onRowDelete: onDeleteData,
                onRowUpdate: onUpdateData,
              }}
              options={{
                actionsColumnIndex: -1,
                search: false,
                paging: false,
                draggable: false,
                showTitle: false,
                toolbar: false,
              }}
            />

          </CardContent>
        }
      </Card>
    </>
  );
}

export default TransactionsTable;
