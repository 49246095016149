import React, { useState } from 'react';
import { useHistory } from 'react-router';

import AddCustomer from './AddCustomer'

import { createCustomer } from './actions';

import { DataProvider } from '../../utils/DataContext';

const initialCustomerState = {
  firstName: '',
  middleName: '',
  lastName: '',
  email: '',
  landline: '',
  mobile: '',
  businessName: '',
  jobTitle: '',
  invoiceTitle: '',
  bankAccount: '',
  gstNumber: '',
}

const AddCustomerContainer = () => {

  const [customer, setCustomer] = useState(initialCustomerState);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();

  const handleFieldChange = (event) => {
    const newState = {
      ...customer,
      [event.target.name]: event.target.value
    }
    setCustomer(newState);
  }

  const handleDateFieldChange = (dateString) => {
    const newState = {
      ...customer,
      dateOfBirth: dateString
    }
    setCustomer(newState);
  }

  const handleSubmit = () => {
    setIsSubmitting(true);
    createCustomer(customer);
  }

  const handleCancel = () => {
    history.push('/customers');
  }

  const addCustomerContext = {
    customer,
    handleCancel,
    handleDateFieldChange,
    handleFieldChange,
    handleSubmit,
    isSubmitting,
  }

  return (
    <DataProvider value={addCustomerContext}>
      <AddCustomer />
    </DataProvider>
  );
}

export default AddCustomerContainer;
