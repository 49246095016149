/* eslint-disable react/no-multi-comp */
import React from 'react';

import DateFnsUtils from '@date-io/date-fns';

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  TextField,
} from '@material-ui/core';

import { Add as AddIcon } from '@material-ui/icons';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import MaterialTable from 'material-table';

import {
  ButtonWithLoading,
  Spinner,
} from '../../../../components'

import './CompanyStatements.css';

const useStyles = makeStyles({
  action: {
    alignSelf: 'center',
    marginTop: 0,
  },
});

const columns = [
  {
    title: 'Statement Date',
    field: 'statementDate',
    type: 'date',
  },
  {
    title: 'Current Assets',
    field: 'totalCurrentAssets',
    type: 'currency',
    align: 'left'
  },
  {
    title: 'Total Assets',
    field: 'totalAssets',
    type: 'currency',
    align: 'left'
  },
  {
    title: 'Current Liabilities',
    field: 'totalCurrentLiabilities',
    type: 'currency',
    align: 'left'
  },
  {
    title: 'Total Liabilities',
    field: 'totalLiabilities',
    type: 'currency',
    align: 'left'
  },
  {
    title: 'Total Equity',
    field: 'totalEquity',
    type: 'currency',
    align: 'left'
  },
  {
    title: 'Sales',
    field: 'totalTradingIncome',
    type: 'currency',
    align: 'left'
  },
  {
    title: 'Gross Profit',
    field: 'grossProfit',
    type: 'currency',
    align: 'left'
  },
  {
    title: 'Net Profit',
    field: 'netProfit',
    type: 'currency',
    align: 'left'
  },
  {
    title: 'Operating Cash Flow',
    field: 'netCashFlowsFromOperatingActivities',
    type: 'currency',
    align: 'left'
  },
  {
    title: 'Supplier Purchase',
    field: 'supplierPurchase',
    type: 'currency',
    align: 'left'
  },
  {
    title: 'Credit Sales',
    field: 'creditSales',
    type: 'currency',
    align: 'left'
  },
  {
    title: 'Average Account Payable',
    field: 'averageAccountsPayable',
    type: 'currency',
    align: 'left'
  },
  {
    title: 'Average Account Receivable',
    field: 'avarageAccountsReceivable',
    type: 'currency',
    align: 'left'
  },
];

const StatementGridItem = ({children}) => (
  <Grid
    item
    xs={6}
    md={3}
    lg={2}
  >
    {children}
  </Grid>
);

const AddCompanyStatementPanel = ({
  isSaving,
  data,
  onSave,
  onFieldChange,
}) => {
  return(
    <Grid
      container
      spacing={2}
    >
      <StatementGridItem>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disabled={isSaving}
            disableToolbar
            format="dd/MM/yyyy"
            fullWidth
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            label="Date"
            name="statementDate"
            onChange={(dateString) => onFieldChange('statementDate', dateString)}
            value={data.statementDate}
            variant="inline"
          />
        </MuiPickersUtilsProvider>
      </StatementGridItem>
      <StatementGridItem>
        <TextField
          disabled={isSaving}
          fullWidth
          label="Current Assets"
          name="totalCurrentAssets"
          onChange={(event) => onFieldChange('totalCurrentAssets', event.target.value)}
          type="number"
          value={data.totalCurrentAssets}
        />
      </StatementGridItem>
      <StatementGridItem>
        <TextField
          disabled={isSaving}
          fullWidth
          label="Total Assets"
          name="totalAssets"
          onChange={(event) => onFieldChange('totalAssets', event.target.value)}
          type="number"
          value={data.totalAssets}
        />
      </StatementGridItem>
      <StatementGridItem>
        <TextField
          disabled={isSaving}
          fullWidth
          label="Current Liabilities"
          name="totalCurrentLiabilities"
          onChange={(event) => onFieldChange('totalCurrentLiabilities', event.target.value)}
          type="number"
          value={data.totalCurrentLiabilities}
        />
      </StatementGridItem>
      <StatementGridItem>
        <TextField
          disabled={isSaving}
          fullWidth
          label="Total Liabilities"
          name="totalLiabilities"
          onChange={(event) => onFieldChange('totalLiabilities', event.target.value)}
          type="number"
          value={data.totalLiabilities}
        />
      </StatementGridItem>
      <StatementGridItem>
        <TextField
          disabled={isSaving}
          fullWidth
          label="Total Equity"
          name="totalEquity"
          onChange={(event) => onFieldChange('totalEquity', event.target.value)}
          type="number"
          value={data.totalEquity}
        />
      </StatementGridItem>
      <StatementGridItem>
        <TextField
          disabled={isSaving}
          disabled={isSaving}
          fullWidth
          label="Sales"
          name="totalTradingIncome"
          onChange={(event) => onFieldChange('totalTradingIncome', event.target.value)}
          type="number"
          value={data.totalTradingIncome}
        />
      </StatementGridItem>
      <StatementGridItem>
        <TextField
          disabled={isSaving}
          fullWidth
          label="Gross Profit"
          name="grossProfit"
          onChange={(event) => onFieldChange('grossProfit', event.target.value)}
          type="number"
          value={data.grossProfit}
        />
      </StatementGridItem>
      <StatementGridItem>
        <TextField
          disabled={isSaving}
          fullWidth
          label="Net Profit"
          name="netProfit"
          onChange={(event) => onFieldChange('netProfit', event.target.value)}
          type="number"
          value={data.netProfit}
        />
      </StatementGridItem>
      <StatementGridItem>
        <TextField
          disabled={isSaving}
          fullWidth
          label="Operating Cash Flow"
          name="netCashFlowsFromOperatingActivities"
          onChange={(event) => onFieldChange('netCashFlowsFromOperatingActivities', event.target.value)}
          type="number"
          value={data.netCashFlowsFromOperatingActivities}
        />
      </StatementGridItem>
      <StatementGridItem>
        <TextField
          disabled={isSaving}
          fullWidth
          label="Supplier Purchase"
          name="supplierPurchase"
          onChange={(event) => onFieldChange('supplierPurchase', event.target.value)}
          type="number"
          value={data.supplierPurchase}
        />
      </StatementGridItem>
      <StatementGridItem>
        <TextField
          disabled={isSaving}
          fullWidth
          label="Credit Sales"
          name="creditSales"
          onChange={(event) => onFieldChange('creditSales', event.target.value)}
          type="number"
          value={data.creditSales}
        />
      </StatementGridItem>
      <StatementGridItem>
        <TextField
          disabled={isSaving}
          fullWidth
          label="Average Accounts Payable"
          name="averageAccountsPayable"
          onChange={(event) => onFieldChange('averageAccountsPayable', event.target.value)}
          type="number"
          value={data.averageAccountsPayable}
        />
      </StatementGridItem>
      <StatementGridItem>
        <TextField
          disabled={isSaving}
          fullWidth
          label="Average Accounts Receivable"
          name="avarageAccountsReceivable"
          onChange={(event) => onFieldChange('avarageAccountsReceivable', event.target.value)}
          type="number"
          value={data.avarageAccountsReceivable}
        />
      </StatementGridItem>
      <StatementGridItem>
        <ButtonWithLoading
          color="primary"
          defaultLabel="Add Statement"
          disabled={isSaving}
          isLoading={isSaving}
          loadingLabel="Adding..."
          onClick={onSave}
          variant="contained"
        />
      </StatementGridItem>
    </Grid>
  );
}

const CompanyStatements = ({
  isLoading,
  newStatement,
  onAddStatement,
  onDeleteStatement,
  onUpdateStatement,
  statements,
  isSaving,
  onStatementFieldChange,
}) => {

  const classes = useStyles();

  return (
    <Card>
      <CardHeader
        classes={{
          action: classes.action,
        }}
        title="Company Statements"
      />
      <Divider />
      {isLoading ?
        <Spinner /> :
        <CardContent style={{ padding: 0, paddingBottom: 0 }}>
          <div style={{ padding: 16 }}>
            <AddCompanyStatementPanel
              isSaving={isSaving}
              data={newStatement}
              onSave={onAddStatement}
              onFieldChange={onStatementFieldChange}
            />
          </div>
          <Divider />
          <div className="statements-table">
            <MaterialTable
              style={{height: '5000px'}}
              columns={columns}
              components={{
                Container: props => <div>{props.children}</div>,
              }}
              data={statements}
              editable={{
                onRowDelete: onDeleteStatement,
                onRowUpdate: onUpdateStatement,
              }}
              options={{
                paging: false,
                draggable: false,
                search: false,
                showTitle: false,
                toolbar: false,
              }}
              title="Company Statements"
            />
          </div>
        </CardContent>
      }
    </Card>
  );
}

export default CompanyStatements;
