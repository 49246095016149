import Parse from 'parse';
import { routes } from '../const'

const handleParseError = (err) => {

  switch (err.code) {
    case Parse.Error.INVALID_SESSION_TOKEN:
      Parse.User.logOut();
      window.location.href = routes.LOGIN;
      break;
    default:
      break;
  }
}

export default handleParseError;
