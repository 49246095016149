import Parse from 'parse';

async function fetchCustomers() {
  try {
    const Customer = Parse.Object.extend('Customer');
    const query = new Parse.Query(Customer);
    query.ascending('firstName');
    const response = await query.find();

    const payload = response.map((customer) => ({
      id: customer.id,
      name: `${customer.get('firstName')} ${customer.get('middleName')} ${customer.get('lastName')}`,
    }));

    return payload;

  } catch (error) {
    console.error('fetchCustomers error', error);
    return [];
  }
}

export default fetchCustomers;
