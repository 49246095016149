import React, { useContext, useEffect, useState } from 'react';
import {
  useParams,
} from 'react-router';

import Syndication from './Syndication';

import {
  fetchInitData,
  saveOwnerTransaction,
  saveSyndicationShare,
} from './actions';

import { RouteContext } from '../../App';

const SyndicationContainer = () => {

  const { setCurrentRoute } = useContext(RouteContext);
  const { syndicationId } = useParams();

  // Data states
  const [ dataSyndication, setDataSyndication ] = useState({});
  const [ dataShares, setDataShares ] = useState([]);
  const [ dataTransactions, setDataTransactions ] = useState([]);

  // Loading states
  const [ isLoading, setIsLoading ] = useState(true);
  const [ isSaving, setIsSaving ] = useState(false);


  useEffect(() => {
    setCurrentRoute('customers');
    _initData();
  }, []);

  const _initData = async () => {
    const result = await fetchInitData(syndicationId);
    _splitRecords(result);
    setIsLoading(false);
  }

  const _splitRecords = (records) => {
    setDataSyndication(records.syndication);
    setDataShares(records.shares);
    setDataTransactions(records.transactions);
  };

  // Share CURD
  const handleShareCreate = async (data) => {
    setIsSaving(true);
    await _saveShare(data);
    setIsSaving(false);
  }

  const handleShareDelete = async (data) => {
    await _saveShare(data, 'delete');
  }

  const handleShareUpdate = async (data) => {
    await _saveShare(data, 'update');
  }

  const _saveShare = async (data, method) => {
    const result = await saveSyndicationShare({ ...data, syndicationId }, method);
    _splitRecords(result);
  }

  // Transaction CURD
  const handleTransactionCreate = async (data) => {
    setIsSaving(true);
    await _saveTransaction(data);
    setIsSaving(false);
  }

  const handleTransactionDelete = async (data) => {
    await _saveTransaction(data, 'delete');
  }

  const handleTransactionUpdate = async (data) => {
    await _saveTransaction(data, 'update');
  }

  const _saveTransaction = async (data, method) => {
    const result = await saveOwnerTransaction({ ...data, syndicationId }, method);
    _splitRecords(result);
  }

  return(
    <Syndication
      dataShares={dataShares}
      dataSyndication={dataSyndication}
      dataTransactions={dataTransactions}
      isLoading={isLoading}
      isSaving={isSaving}
      onShareCreate={handleShareCreate}
      onShareDelete={handleShareDelete}
      onShareUpdate={handleShareUpdate}
      onTransactionCreate={handleTransactionCreate}
      onTransactionDelete={handleTransactionDelete}
      onTransactionUpdate={handleTransactionUpdate}
    />
  );
}

export default SyndicationContainer;
