import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import { ButtonWithLoading, Spinner, SubTitle } from '../../../../components';

import useStyles from './styles';

const CustomerPortalAccess = ({
  credentials,
  hasAccess,
  isLoading,
  isRegistering,
  isSubmitting,
  onCancelGrantAccess,
  onDelete,
  onFieldChange,
  onGrantAccess,
  onSubmit,
}) => {

  const classes = useStyles();

  const {
    username,
    password,
  } = credentials;

  const [error, setError] = useState(false);

  const handleBeforeFormSubmit = (event) => {
    event.preventDefault();

    // If username or password is empty, display error state
    if (!username || !password) {
      setError(true);
      return;
    }

    // Clear error state if there's any
    if (error) setError(false);

    // Submit form
    onSubmit();
  }

  return (
    <Paper className={classes.paper}>
      <Grid
        container
        spacing={6}
      >
        {isLoading ?
          <Grid
            item
            xs={12}
          >
            <Spinner />
          </Grid> :
          <Grid
            item
            xs={6}
          >
            <SubTitle>Portal Access</SubTitle>

            {/* Grand access button */}
            {hasAccess || isRegistering ?
              <>
                {/* Credential fields */}
                <div className={classes.formGroup}>
                  <TextField
                    fullWidth
                    label="User Email"
                    margin="normal"
                    name="username"
                    onChange={onFieldChange}
                    required
                    value={username}
                  />
                  <TextField
                    fullWidth
                    label="User Password"
                    margin="normal"
                    name="password"
                    onChange={onFieldChange}
                    required
                    type="password"
                    value={password}
                  />
                </div>

                {/* Form actions */}
                <div className={classes.formActions}>
                  <ButtonWithLoading
                    color="primary"
                    defaultLabel="Save"
                    isLoading={isSubmitting}
                    loadingLabel="Saving..."
                    onClick={handleBeforeFormSubmit}
                    variant="contained"
                  />

                  {isRegistering ?
                    <Button
                      color="default"
                      disabled={isSubmitting}
                      onClick={onCancelGrantAccess}
                      style={{ marginLeft: 8, marginRight: 'auto' }}
                    >
                      Cancel
                    </Button>
                    :
                    <ButtonWithLoading
                      color="secondary"
                      defaultLabel="Remove Access"
                      isLoading={isSubmitting}
                      loadingLabel="Removing Access..."
                      onClick={onDelete}
                      variant="contained"
                    />
                  }
                </div>
              </>
              :
              <>
                {/* Grant access button */}
                <Button
                  color="primary"
                  onClick={onGrantAccess}
                  variant="contained"
                >
                  Grant Portal Access
                </Button>
              </>
            }
          </Grid>
        }
      </Grid>
    </Paper>
  );
}

export default CustomerPortalAccess;
