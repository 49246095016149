import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core';

import {
  ButtonWithLoading,
  ComboBox,
} from '../index';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: 16
    }
  },
});

const ComboBoxWithButton = ({
  label,
  options,
  color,
  defaultLabel,
  disabled,
  fullWidth,
  loadingLabel,
  isLoading,
  onClick,
  variant,
}) => {

  const classes = useStyles();

  const [ value, setValue ] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  const handleClick = () => {
    if (value === null || value === undefined) return;

    setValue(null);
    onClick(value);
  }

  return (
    <div className={classes.wrapper}>
      <ComboBox
        disabled={isLoading}
        label={label}
        options={options}
        onChange={handleChange}
      />
      <ButtonWithLoading
        classes={classes.button}
        color={color}
        defaultLabel={defaultLabel}
        disabled={disabled}
        fullWidth={fullWidth}
        loadingLabel={loadingLabel}
        isLoading={isLoading}
        onClick={handleClick}
        variant={variant}
      />
    </div>
  );
}

export default ComboBoxWithButton;
