/* eslint-disable no-script-url */

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { DataConsumer } from '../../../../utils/DataContext';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { routes } from '../../../../const';

// Generate Order Data
function createData(id, name, balance, lastLogin) {
  return { id, name, balance, lastLogin };
}

const rows = [
  createData(1, 'Sam Smith', 10000.00, '18 Sep 2019, 1:00pm'),
  createData(2, 'John Joe', 20000.00, '17 Sep 2019, 2:00pm'),
  createData(3, 'David Doe', 15000.00, '17 Sep 2019, 3:00pm'),
];

export default function HorsesList() {
  return (
    <React.Fragment>
      <Table
        size="small"
      >
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">Edit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <DataConsumer>
            {(horses) => (
              horses.map(({ id, name }) => (
                <TableRow key={id}>
                  <TableCell>{name}</TableCell>
                  <TableCell align="right" padding="none">
                    <IconButton component={RouterLink} to={`${routes.HORSE_DETAILS}/${id}`}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </DataConsumer>
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
