import React, { useContext, useState } from 'react';

import SignIn from './SignIn';

import { LoggingIn } from './actions';

import { RouteContext } from '../../App';

const SignInContainer = () => {

  const { setIsAuthenticated } = useContext(RouteContext);

  const [authenticationError, setAuthenticationError] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [credentials, setCredentials] = useState({
    userName: '',
    password: '',
  })

  const handleFieldChange = (event) => {
    const newState = {
      ...credentials,
      [event.target.name]: event.target.value
    }
    setCredentials(newState);
  }

  async function handleSubmit() {

    setIsLoggingIn(true);

    const loggedIn = await LoggingIn(credentials);

    setIsLoggingIn(false);

    if (!loggedIn) {
      setAuthenticationError(true);
      return;
    }

    setAuthenticationError(false);
    setIsAuthenticated(true);
  }

  const handleCloseErrorNotification = () => {
    setAuthenticationError(false);
  }

  return(
    <SignIn
      authenticationError={authenticationError}
      credentials={credentials}
      isLoggingIn={isLoggingIn}
      onCloseErrorNotification={handleCloseErrorNotification}
      onFieldChange={handleFieldChange}
      onSubmit={handleSubmit}
    />
  );
}

export default SignInContainer;
