import Parse from 'parse';

import { handleParseError } from '../../../utils';

async function fetchCustomers() {
  try {

    const Customer = Parse.Object.extend('Customer');
    const query = new Parse.Query(Customer);

    const response = await query.find();

    const payload = await response
      .map((customer) => ({
        id: customer.id,
        name: `${customer.get('firstName')} ${customer.get('middleName')} ${customer.get('lastName')}`,
        email: customer.get('email'),
        mobile: customer.get('mobile'),
      }))

      // Clone array as sort will mutate the original array
      .slice()

      // Sort array in ascending order by name
      .sort(function (a, b) {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        if (b.name.toLowerCase() > a.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });

    return payload;

  } catch (error) {
    handleParseError(error);
  }
}

export default fetchCustomers;
