import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import HorseTransactions from './HorseTransactions';

import {
  Spinner,
} from '../../../../components';

import {
  fetchHorseTransactions,
  saveHorseTransactions,
} from './actions';

const HorseTransactionsContainer = () => {

  const { horseId } = useParams();

  const [ dataTransactions, setDataTransactions ] = useState([]);

  // Loading states
  const [ isLoading, setIsLoading ] = useState(true);
  const [ isSaving, setIsSaving ] = useState(false);

  useEffect(() => {
    _initData();
  }, []);

  const _initData = async () => {
    const result = await fetchHorseTransactions(horseId);
    setDataTransactions(result);
    setIsLoading(false);
  }

  // Transaction CURD
  const handleTransactionCreate = async (data) => {
    setIsSaving(true);
    await _saveTransaction(data);
    setIsSaving(false);
  }

  const handleTransactionDelete = async (data) => {
    await _saveTransaction(data, 'delete');
  }

  const handleTransactionUpdate = async (data) => {
    await _saveTransaction(data, 'update');
  }

  const _saveTransaction = async (data, method) => {
    const result = await saveHorseTransactions({ ...data, horseId }, method);
    setDataTransactions(result);
  }

  return (
    isLoading ?
      <Spinner /> :
      <HorseTransactions
        data={dataTransactions}
        isLoading={isLoading}
        isSaving={isSaving}
        onCreate={handleTransactionCreate}
        onDelete={handleTransactionDelete}
        onUpdate={handleTransactionUpdate}
      />
  );
}

export default HorseTransactionsContainer;
