import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { CustomerPortalAccess, CustomerProfile, HorseShares } from './components';
import useStyles from './styles';

import { Title  } from '../../components';
import { routes } from '../../const';
import { a11yProps } from '../../utils';

const CustomerDetails = ({
  handleTabChange,
  tabValue,
}) => {

  const classes = useStyles();

  let content;

  switch (tabValue) {
    case 0:
      content = <CustomerProfile />
      break;
    case 1:
      content = <HorseShares />
      break;
    case 2:
      content = <CustomerPortalAccess />
      break;
    default:
      content = <HorseShares />
      break;
  }

  return (
    <>
      <Grid item xs={12}>
        <Link component={RouterLink} to={routes.CUSTOMERS} className={classes.backLink}>
          Back to Customers List
        </Link>
        <Title>Customer Details</Title>
      </Grid>
      <Grid item xs={12}>
        <AppBar position="static" className={classes.tab}>
          <Tabs
            aria-label="simple tabs example"
            indicatorColor="secondary"
            onChange={handleTabChange}
            value={tabValue}
          >
            <Tab label="Profile" {...a11yProps(0)} />
            <Tab label="Syndication" {...a11yProps(1)} />
            <Tab label="Portal Access" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
      </Grid>
      <Grid item xs={12}>
        {content}
      </Grid>
    </>
  );
}

export default CustomerDetails;
