import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import { PrivateRoute } from './components';
import { Main as MainLayout } from './layouts';
import { routes } from './const';

import {
  // Auth
  SignIn as SignInView,

  // Customers
  Customers as CustomersView,
  AddCustomer as AddCustomerView,
  CustomerDetails as CustomerDetailsView,

  // Horses
  Horses as HorsesView,
  AddHorse as AddHorseView,
  HorseDetails as HorseDetailsView,

  // Syndication
  Syndication as SyndicationView,

  // Investment
  Investment as InvestmentView,
  InvestmentHorseShares as InvestmentHorseSharesView,
  MarketOverview as MarketOverviewView,
  CompanyTransactions as CompanyTransactionsView,

} from './views';

const Routes = ({ isAuthenticated }) => {

  return (
    <Switch>
      <Redirect
        exact
        from={routes.ROOT}
        to={isAuthenticated ? routes.CUSTOMERS : routes.LOGIN}
      />

      {/* Auth */}
      <Route
        exact
        path={routes.LOGIN}
        render={({ location }) =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: routes.INVESTMENT,
                state: { from: location },
              }}
            />
          ) : (
            <SignInView />
          )
        }
      />

      {/* Customers */}
      <PrivateRoute
        exact
        isAuthenticated={isAuthenticated}
        path={routes.CUSTOMERS}
      >
        <MainLayout>
          <CustomersView />
        </MainLayout>
      </PrivateRoute>

      <PrivateRoute
        exact
        isAuthenticated={isAuthenticated}
        path={routes.ADD_CUSTOMER}
      >
        <MainLayout>
          <AddCustomerView />
        </MainLayout>
      </PrivateRoute>

      <Redirect
        exact
        from={`${routes.CUSTOMER_DETAILS}/:customerId`}
        to={`${routes.CUSTOMER_DETAILS}/:customerId/profile`}
      />

      <PrivateRoute
        exact
        isAuthenticated={isAuthenticated}
        path={`${routes.CUSTOMER_DETAILS}/:customerId/:content`}
      >
        <MainLayout>
          <CustomerDetailsView />
        </MainLayout>
      </PrivateRoute>

      {/* Horses */}
      <PrivateRoute
        exact
        isAuthenticated={isAuthenticated}
        path={routes.HORSES}
      >
        <MainLayout>
          <HorsesView />
        </MainLayout>
      </PrivateRoute>

      <PrivateRoute
        exact
        isAuthenticated={isAuthenticated}
        path={routes.ADD_HORSE}
      >
        <MainLayout>
          <AddHorseView />
        </MainLayout>
      </PrivateRoute>

      <Redirect
        exact
        from={`${routes.HORSE_DETAILS}/:horseId`}
        to={`${routes.HORSE_DETAILS}/:horseId/profile`}
      />

      <PrivateRoute
        exact
        isAuthenticated={isAuthenticated}
        path={`${routes.HORSE_DETAILS}/:horseId/:content`}
      >
        <MainLayout>
          <HorseDetailsView />
        </MainLayout>
      </PrivateRoute>

      {/* Syndication */}
      <Redirect
        exact
        from={`${routes.SYNDICATION}/:syndicationId`}
        to={`${routes.SYNDICATION}/:syndicationId/transactions`}
      />

      <PrivateRoute
        exact
        isAuthenticated={isAuthenticated}
        path={`${routes.SYNDICATION}/:syndicationId/:content`}
      >
        <MainLayout>
          <SyndicationView />
        </MainLayout>
      </PrivateRoute>

      {/* Investment */}
      <PrivateRoute
        exact
        isAuthenticated={isAuthenticated}
        path={routes.INVESTMENT}
      >
        <MainLayout>
          <InvestmentView />
        </MainLayout>
      </PrivateRoute>

      {/* Investment */}
      <PrivateRoute
        exact
        isAuthenticated={isAuthenticated}
        path={`${routes.INVESTMENT_HORSE_SHARES}/:investmentHorseId`}
      >
        <MainLayout>
          <InvestmentHorseSharesView />
        </MainLayout>
      </PrivateRoute>

      {/* Market Overview */}
      <PrivateRoute
        exact
        isAuthenticated={isAuthenticated}
        path={routes.MARKET_OVERVIEW}
      >
        <MainLayout>
          <MarketOverviewView />
        </MainLayout>
      </PrivateRoute>

      {/* Company Transactions */}
      <PrivateRoute
        exact
        isAuthenticated={isAuthenticated}
        path={routes.COMPANY_TRANSACTIONS}
      >
        <MainLayout>
          <CompanyTransactionsView />
        </MainLayout>
      </PrivateRoute>

      {/* Redirect to root for all 404 pages */}
      <Redirect to={routes.ROOT} />
    </Switch>
  );
}

export default Routes;
