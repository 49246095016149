import Parse from 'parse';

async function fetchCustomer(customerId) {
  const Customer = Parse.Object.extend('Customer');
  const query = new Parse.Query(Customer);

  const customer = await query.get(customerId);

  return customer
}

async function createUser(credentials) {
  const user = new Parse.User();

  user.set('username', credentials.username);
  user.set('email', credentials.username);
  user.set('password', credentials.password);

  var userACL = new Parse.ACL();
  userACL.setRoleReadAccess('Administrator', true);
  userACL.setRoleWriteAccess('Administrator', true);
  user.setACL(userACL);

  const newUser = await user.signUp();

  return newUser;
}

async function createPortalUserAction(customerId, credentials) {
  try {
    const customer = await fetchCustomer(customerId);
    const user = await createUser(credentials);

    customer.set('user', user);

    await customer.save();

    return true;
  } catch (error) {
    console.error('Error while signing up user', error);
    return false;
  }
}

export default createPortalUserAction;
