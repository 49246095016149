import Parse from 'parse';
import { fetchMarketsData } from './index';

const saveMarketData = async (marketData, method = 'add') => {
  try {

    const Market = Parse.Object.extend('Market');
    const market = new Market();

    // Add market id if is update
    if (method === 'update' && marketData.id) {
      market.id = marketData.id;
    }

    market.set('year', parseInt(marketData.year));
    market.set('type', marketData.type);
    market.set('data', marketData.data);

    // Save market data
    const result = await market.save();

    // Get updated market fata list
    const payload = await fetchMarketsData();

    return payload;

  } catch (error) {
    console.error('saveMarketData error', error);
    return [];
  }

}

export default saveMarketData;
