import Parse from 'parse';
import { fetchInvestmentCustomers } from './index';

const saveInvestmentCustomer = async (investmentCustomerData, method = 'add') => {
  try {

    const InvestmentCustomer = Parse.Object.extend('InvestmentCustomer');
    const investmentCustomer = new InvestmentCustomer();

    // Add investment customer id if is update
    if (method === 'update' && investmentCustomerData.id) {
      investmentCustomer.id = investmentCustomerData.id;
    }

    // Add customer pointer if add new
    if (method === 'add') {
      const Customer = Parse.Object.extend('Customer');
      const customer = new Customer();
      customer.id = investmentCustomerData.customerId;
      investmentCustomer.set('customer', customer);
    }

    investmentCustomer.set('joinedDate', new Date(investmentCustomerData.joinedDate));
    investmentCustomer.set('shares', parseFloat(investmentCustomerData.shares));

    // Save investment customer
    await investmentCustomer.save();

    // Get updated investment customer list
    const payload = await fetchInvestmentCustomers();

    return payload;

  } catch (error) {
    console.error('saveInvestmentHorse error', error);
    return [];
  }

}

export default saveInvestmentCustomer;
