import React, { useState } from 'react';

import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { AuthenticationErrorNotification } from './components';
import { ButtonWithLoading, Copyright } from '../../components';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn({
  authenticationError,
  credentials,
  isLoggingIn,
  onCloseErrorNotification,
  onFieldChange,
  onSubmit,
}) {
  const classes = useStyles();

  const {
    userName,
    password
  } = credentials;

  const [error, setError] = useState(false);

  const handleBeforeFormSubmit = (event) => {
    event.preventDefault();

    // If username or password is empty, display error state
    if (!userName || !password) {
      setError(true);
      return;
    }

    // Clear error state if there's any
    if (error) setError(false);

    // Submit form
    onSubmit();
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>

        {/* Authentication error snackbar */}
        {authenticationError ?
          <AuthenticationErrorNotification onClose={onCloseErrorNotification} />
          : null
        }

        {/* Login form */}
        <form className={classes.form} noValidate>
          <TextField
            autoComplete="email"
            autoFocus
            error={error && !userName}
            fullWidth
            helperText={error && !userName ? 'This is a required field' : null}
            id="email"
            label="Email Address"
            margin="normal"
            name="userName"
            onChange={onFieldChange}
            required
            value={userName}
            variant="outlined"
          />
          <TextField
            autoComplete="current-password"
            error={error && !password}
            fullWidth
            helperText={error && !password ? 'This is a required field' : null}
            id="password"
            label="Password"
            margin="normal"
            name="password"
            onChange={onFieldChange}
            required
            type="password"
            value={password}
            variant="outlined"
          />
          <ButtonWithLoading
            className={classes.submit}
            color="primary"
            defaultLabel="Sign In"
            fullWidth
            isLoading={isLoggingIn}
            loadingLabel="Signing In..."
            onClick={handleBeforeFormSubmit}
            variant="contained"
          />
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
