import Parse from 'parse';
import { fetchInvestmentHorses } from '../actions';

// Error Messages
const ERROR_INVALID_DATA = 'Investment Horse Id must be supplied';

/**
 * Delete Investment Horse
 * @description
 * 1. Delete Investment Horse Record
 * 2. Delete all related Investment Horse Share Records
 * 3. Return all Investment Horse Records
 * @param {string} investmentHorseId
 * @return {object[]}
 */
const deleteInvestmentHorse = async (investmentHorseId) => {
  try {

    if (!investmentHorseId) throw new Error(ERROR_INVALID_DATA);

    // Delete Investment Horse Record
    const investmentHorse = new (Parse.Object.extend('InvestmentHorse'))();
    investmentHorse.id = investmentHorseId;
    await investmentHorse.destroy();

    // Delete all related Investment Horse Share Records
    const InvestmentHorseShare = Parse.Object.extend('InvestmentHorseShare');
    const investmentHorseShareQuery = new Parse.Query(InvestmentHorseShare);
    investmentHorseShareQuery.equalTo('investmentHorse', investmentHorse);
    const investmentHorseShareRecords = await investmentHorseShareQuery.find();
    await Parse.Object.destroyAll(investmentHorseShareRecords);

    // Return all Investment Horse Records
    const payload = await fetchInvestmentHorses();
    return payload;

  } catch (error) {
    console.error('deleteInvestmentHorse error', error);
    return [];
  }
}

export default deleteInvestmentHorse;
