import Parse from 'parse';

async function fetchSyndications(customerId) {
  try {

    const Customer = Parse.Object.extend('Customer');
    const customer = new Customer();
    customer.id = customerId;

    const Syndication = Parse.Object.extend('Syndication');
    const query = new Parse.Query(Syndication);
    query.equalTo('customer', customer);
    query.include('horse');

    const response = await query.find();

    const generatePayload = async (syndications) => {
      const output = await syndications.map(async (syndication) => {

        // Get customer name
        const horse = syndication.get('horse');

        // Get current shares
        const sharesQuery = new Parse.Query('SyndicationShare');
        sharesQuery.equalTo('syndication', syndication);
        sharesQuery.descending('startDate');
        const share = await sharesQuery.first();

        return {
          id: syndication.id,
          horseName: horse.get('horseName'),
          horseId: horse.id,
          sharePercentage: share.get('sharePercentage'),
          frozeDepositUntil: syndication.get('frozeDepositUntil'),
        }
      });

      return Promise.all(output);
    }

    const payload = await generatePayload(response);

    return payload;

  } catch (error) {
    console.error('fetchSyndications error', error);
    return [];
  }
}

export default fetchSyndications;
