import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import {
  createInvestmentHorse,
  deleteInvestmentHorse,
  fetchHorses,
  fetchInvestmentHorses,
  saveInvestmentHorse,
} from './actions';

import InvestmentHorses from './InvestmentHorses';

import { InvestmentContext } from '../../InvestmentContainer';

import { routes } from '../../../../const';
import { parseFloatNumber } from '../../../../utils';

const InvestmentHorsesContainer = () => {

  const { investmentProduct } = useContext(InvestmentContext);

  const history = useHistory();

  const [ horses, setHorses ] = useState([]);
  const [ horseOptions, setHorseOptions ] = useState([]);
  const [ investmentHorses, setInvestmentHorses ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ isSaving, setIsSaving ] = useState(false);

  const _initData = async () => {
    const [ horses, investmentHorses ] = await Promise.all([
      fetchHorses(),
      fetchInvestmentHorses()
    ]);
    setHorses(horses);
    setInvestmentHorses(investmentHorses);
    setHorseOptions(_horseOptions(horses, investmentHorses));
    setIsLoading(false);
  }

  useEffect(() => {
    _initData();
  }, []);

  /**
   * Material table d horse selector options
   * @returns {object}
   */
  const _horseOptions = (horses, investmentHorses) => {
    const options = horses
      .filter(({id}) => !investmentHorses.some(({horseId}) => horseId === id))
      .map(({id, horseName}) => ({ title: horseName, id: id }));
    return options;
  }

  /**
   * Handle Edit
   * @description Redirect to investment horse shares page
   * @param {object} event
   * @param {object} rowData
   * @param {string} rowData.id
   */
  const handleEdit = (event, rowData) => {
    history.push(`${routes.INVESTMENT_HORSE_SHARES}/${rowData.id}`);
  }

  const handleAddInvestmentHorse = async (newData) => {
    try {
      setIsSaving(true);

      const response = await createInvestmentHorse({
        ...newData,
        shares: parseFloatNumber(newData.shares),
        investmentProductId: investmentProduct.id,
      });

      setInvestmentHorses(response);
      setHorseOptions(_horseOptions(horses, response));

    } catch (error) {
      console.error('handleAddInvestmentHorse error', error);
    } finally {
      setIsSaving(false);
    }
  }

  const handleDeleteInvestmentHorse = async ({ id  }) => {
    const response = await deleteInvestmentHorse(id);
    setInvestmentHorses(response);
    setHorseOptions(_horseOptions(horses, response));
  }

  const _saveInvestmentHorse = async (data, method) => {
    try {
      const result = await saveInvestmentHorse({
        ...data,
        investmentProductId: investmentProduct.id,
      }, method);
      setInvestmentHorses(result);
      setHorseOptions(_horseOptions(horses, result));
    } catch (error) {
      console.error('Save investment horse error', error);
    }
  }

  return(
    <InvestmentHorses
      horseOptions={horseOptions}
      investmentHorses={investmentHorses}
      isLoading={isLoading}
      isSaving={isSaving}
      onAddData={handleAddInvestmentHorse}
      onEdit={handleEdit}
      onDeleteData={handleDeleteInvestmentHorse}
    />
  );
}

export default InvestmentHorsesContainer;
