import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

const Spinner = () => {
  return(
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 300 }}>
      <CircularProgress />
    </div>
  );
}

export default Spinner;
