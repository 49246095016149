/* eslint-disable no-script-url */

import React from 'react';
import Typography from '@material-ui/core/Typography';
import AnchorLink from '@material-ui/core/Link';

export default function CustomerList() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <AnchorLink color="inherit" href="http://taniwhagroup.com">
        Taniwha Group
      </AnchorLink>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}