import React, { useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography'

import { ButtonWithLoading, ImageUploader, Spinner } from '../../../../components';

const HorseNewsDetailsDialog = ({
  dialogOpen,
  dialogSubTitle,
  dialogTitle,
  hasError,
  isLoading,
  isSubmittingNews,
  newsDetails,
  onDateFieldChange,
  onDialogClose,
  onDialogSubmit,
  onNewsDelete,
  onFieldChange,
  onImageChange,
}) => {

  const {
    date,
    title,
    description,
    imageURL,
    linkURL,
  } = newsDetails;

  const [error, setError] = useState(false);

  // Form submit middleware
  const handleBeforeFormSubmit = (event) => {
    event.preventDefault();

    // If horse name is empty, display error state
    if (!date || !title) {
      setError(true);
      return;
    }

    // Clear error state if there's any
    if (error) setError(false);

    // Submit form
    onDialogSubmit();
  }

  // Dialog close middleware
  const handleDialogClose = (event) => {
    event.preventDefault();
    if (error) setError(false);
    onDialogClose();
  }

  // Delete News middleware
  const handleNewsDelete = (event) => {
    event.preventDefault();
    if (error) setError(false);
    onNewsDelete();
  }

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      onClose={onDialogClose}
      open={dialogOpen}
    >
      <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {dialogSubTitle}
        </DialogContentText>
        {isLoading ?
          <Spinner /> :
          <>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                autoFocus
                disableToolbar
                error={error && !date}
                format="dd/MM/yyyy"
                fullWidth
                helperText={error && !date ? 'This is a required field' : null}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                label="News Date"
                margin="normal"
                name="date"
                onChange={onDateFieldChange}
                required
                value={date}
                variant="inline"
              />
            </MuiPickersUtilsProvider>
            <TextField
              error={error && !title}
              fullWidth
              helperText={error && !title ? 'This is a required field' : null}
              id="title"
              label="Title"
              margin="normal"
              name="title"
              onChange={onFieldChange}
              required
              type="text"
              value={title}
            />
            <TextField
              fullWidth
              id="description"
              label="Description"
              margin="normal"
              multiline
              name="description"
              onChange={onFieldChange}
              type="text"
              value={description}
            />
            <TextField
              fullWidth
              id="linkURL"
              label="Link URL"
              margin="normal"
              name="linkURL"
              onChange={onFieldChange}
              type="text"
              value={linkURL}
            />
            <Typography
              color="primary"
              component="h3"
              style={{ marginTop: 16, marginBottom: 8 }}
              variant="subtitle2"
            >
              News Image
            </Typography>
            <ImageUploader
              imageURL={imageURL}
              onImageChange={onImageChange}
            />
          </>
        }
      </DialogContent>
      {isLoading ?
        null :
        <DialogActions style={{ padding: '16px 24px' }}>
          <ButtonWithLoading
            color="primary"
            defaultLabel="Save"
            isLoading={isSubmittingNews}
            loadingLabel="Saving..."
            onClick={handleBeforeFormSubmit}
            variant="contained"
          />
          <Button
            color="default"
            disabled={isSubmittingNews}
            onClick={handleDialogClose}
            style={{ marginRight: 'auto' }}
          >
            Cancel
          </Button>
          {onNewsDelete !== undefined ?
            <ButtonWithLoading
              color="secondary"
              defaultLabel="Delete"
              isLoading={isSubmittingNews}
              loadingLabel="Deleting..."
              onClick={handleNewsDelete}
              variant="contained"
            />
            : null
          }
        </DialogActions>
      }
    </Dialog>
  );
}

export default HorseNewsDetailsDialog;
