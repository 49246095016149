import Parse from 'parse';

async function fetchCustomerDetails(customerId) {
  const Customer = Parse.Object.extend('Customer');
  const query = new Parse.Query(Customer);
  query.equalTo('objectId', customerId);
  const response = await query.first();

  const payload = {
    id: response.id,
    firstName: response.get('firstName'),
    middleName: response.get('middleName'),
    dateOfBirth: response.get('dateOfBirth'),
    lastName: response.get('lastName'),
    email: response.get('email'),
    landline: response.get('landline'),
    mobile: response.get('mobile'),
    businessName: response.get('businessName'),
    jobTitle: response.get('jobTitle'),
    invoiceTitle: response.get('invoiceTitle'),
    bankAccount: response.get('bankAccount'),
    gstNumber: response.get('gstNumber'),
  };

  return payload;
}

export default fetchCustomerDetails;
