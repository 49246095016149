
import React from 'react';

import {
  Grid,
} from '@material-ui/core';

import {
  CompanyStatements,
  GeneralConfig,
  InvestmentCustomers,
  InvestmentHorses,
} from './components';

import { Spinner, Title } from '../../components';

const Investment = ({
  isLoading,
}) => {

  return (
    <>
      {isLoading ?
        <Grid item xs={12}>
          <Spinner />
        </Grid> :
        <>
          <Grid
            item
            xs={12}
          >
            <Title>Investment Dashboard</Title>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <GeneralConfig />
          </Grid>

          <Grid
            item
            xs={12}
          >
            <CompanyStatements />
          </Grid>

          <Grid
            item
            xs={12}
          >
            <InvestmentCustomers />
          </Grid>

          <Grid
            item
            xs={12}
          >
            <InvestmentHorses />
          </Grid>
        </>
      }
    </>
  );
}

export default Investment;
