import Parse from 'parse';

async function createCustomer(customerData) {

  try {

    const Customer = Parse.Object.extend('Customer');
    const customer = new Customer();

    customer.set(customerData);

    const result = await customer.save();

    window.location.href = `/customer/${result.id}`;

  } catch (error) {
    console.error('createCustomer error', error);
  }
}

export default createCustomer;
