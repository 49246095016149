import React, { useState } from 'react';
import MaterialTable from 'material-table';

import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';

import AddIcon from '@material-ui/icons/Add';

import useStyles from '../../styles';

import { HorseNewsDetailsDialog } from '../';

import { Spinner } from '../../../../components';

const HorseNews = ({
  addDialogOpen,
  editDialogOpen,
  isLoading,
  isLoadingNewsDetails,
  isSubmittingNews,
  news,
  newsDetails,
  onAddDialogClose,
  onAddDialogOpen,
  onAddDialogSubmit,
  onDateFieldChange,
  onEditDialogClose,
  onEditDialogOpen,
  onEditDialogSubmit,
  onFieldChange,
  onNewsDelete,
  onImageChange,
}) => {

  const classes = useStyles();

  const tableColumns = [
    { title: 'Date', field: 'date', type: 'date' },
    { title: 'Title', field: 'title', type: 'string' },
    {
      title: 'Image',
      field: 'imageUrl',
      render: ({imageURL}) => (
        imageURL ?
          <img
            src={imageURL}
            style={{width: 48, height: 48, objectFit: 'cover'}}
          />
          : null
      ),
    },
    {
      field: 'edit',
      title: 'Edit',
      type: 'numeric',
      render: ({id}) => (
        <IconButton
          onClick={() => onEditDialogOpen(id)}
        >
          <EditIcon />
        </IconButton>
      )
    }
  ];

  return (
    <>
      {/* Add news button */}
      <Button
        className={classes.addButton}
        color="primary"
        onClick={onAddDialogOpen}
        startIcon={<AddIcon />}
        variant="contained"
      >
        Add horse news
      </Button>

      {/* Horse news table */}
      {isLoading ?
        <Spinner /> :
        <MaterialTable
          title="Horse News"
          data={news}
          options={{
            actionsColumnIndex: -1,
            search: false,
            paging: false,
            draggable: false,
            showTitle: false,
            sorting: false,
            toolbar: false
          }}
          columns={tableColumns}
        />
      }


      {/* Add horse news dialog */}
      <HorseNewsDetailsDialog
        dialogOpen={addDialogOpen}
        dialogSubTitle={'The news displays at the client portal under the related horse details.'}
        dialogTitle={'Add News'}
        isSubmittingNews={isSubmittingNews}
        newsDetails={newsDetails}
        onDateFieldChange={onDateFieldChange}
        onDialogClose={onAddDialogClose}
        onDialogSubmit={onAddDialogSubmit}
        onFieldChange={onFieldChange}
        onImageChange={onImageChange}
      />

      {/* Edit horse news dialog */}
      <HorseNewsDetailsDialog
        dialogOpen={editDialogOpen}
        dialogSubTitle={'The news displays at the client portal under the related horse details.'}
        dialogTitle={'Edit News'}
        isLoading={isLoadingNewsDetails}
        isSubmittingNews={isSubmittingNews}
        newsDetails={newsDetails}
        onDateFieldChange={onDateFieldChange}
        onDialogClose={onEditDialogClose}
        onDialogSubmit={onEditDialogSubmit}
        onFieldChange={onFieldChange}
        onNewsDelete={onNewsDelete}
        onImageChange={onImageChange}
      />
    </>
  );
}

export default HorseNews;
