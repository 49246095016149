import Parse from 'parse';

import { parseImage } from '../../../utils';

async function updateHorseNews(newsData, successCallBack) {
  const News = Parse.Object.extend('News');
  const query = new Parse.Query(News);
  const news = await query.get(newsData.id);

  news.set('date', newsData.date);
  news.set('title', newsData.title);
  news.set('description', newsData.description);
  news.set('image', parseImage(newsData.image));
  news.set('linkURL', newsData.linkURL);

  const result = await news.save();
  if (successCallBack) successCallBack();
}

export default updateHorseNews;
