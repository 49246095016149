import Parse from 'parse';
import { fetchSyndicationsInitData } from './index';

/**
 * Save syndication
 * @param {object} data
 * @param {string} method - create | delete
 */
const saveSyndication = async (data, method = 'create') => {
  try {

    const Syndication = Parse.Object.extend('Syndication');
    const syndication = new Syndication();

    // Point to syndication if is create
    if (method === 'create') {

      // Horse object
      const Horse = Parse.Object.extend('Horse');
      const horse = new Horse();
      horse.id = data.horseId;

      // Get customer object
      const Customer = Parse.Object.extend('Customer');
      const customer = new Customer();
      customer.id = data.customerId;

      // Syndication object
      syndication.set('horse', horse);
      syndication.set('customer', customer);

      // Create new syndication
      const newSyndication = await syndication.save();

      // Share object
      const SyndicationShare = Parse.Object.extend('SyndicationShare');
      const share = new SyndicationShare();
      share.set('syndication', newSyndication);
      share.set('startDate', new Date(data.startDate));
      share.set('sharePercentage', parseFloat(data.sharePercentage));

      // Create new share
      await share.save();
    }

    // Add transaction id if is update
    if (method === 'delete' && data.id) {
      syndication.id = data.id;
      await syndication.destroy();
    }

    // Get updated syndication data
    const payload = await fetchSyndicationsInitData(data.customerId);

    return payload;

  } catch (error) {
    console.error('saveSyndication error', error);
    return [];
  }

}

export default saveSyndication;
