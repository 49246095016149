import Parse from 'parse';

// Error Messages
const ERROR_INVALID_DATA = 'Investment Horse Id, Share Percentage, Start Data and method must be supplied';
const ERROR_INVALID_INVESTMENT_HORSE_SHARE_ID = 'Investment Horse Id, Share Percentage, Start Data and method must be supplied';

/**
 * Save Investment Horse Share
 * @description
 * 1. Create, update or delete Investment Horse Share record
 * 2. Get sharePercentage from latest Investment Horse Share record
 * 3. Update Investment Horse record shares field value
 * @param {object} data
 * @param {string} data.investmentHorseId
 * @param {string} data.investmentHorseShareId
 * @param {number} data.sharePercentage
 * @param {date} data.startDate
 * @param {string} method - create | update | delete
 * @return {boolean}
 */
const saveInvestmentHorseShare = async ({
  investmentHorseId,
  investmentHorseShareId,
  sharePercentage,
  startDate,
}, method) => {
  try {

    if (!investmentHorseId || !sharePercentage && sharePercentage !== 0 || !startDate || !method) {
      throw new Error(ERROR_INVALID_DATA);
    }

    // Create, update or delete Investment Horse Share record
    const InvestmentHorseShare = Parse.Object.extend('InvestmentHorseShare');
    const investmentHorseShare = new InvestmentHorseShare();

    const investmentHorse = new (Parse.Object.extend('InvestmentHorse'))();
    investmentHorse.id = investmentHorseId;

    // Add market id if is update
    if (method === 'update' || method === 'delete') {
      if (!investmentHorseShareId) {
        throw new Error(ERROR_INVALID_INVESTMENT_HORSE_SHARE_ID);
      }
      investmentHorseShare.id = investmentHorseShareId;
    }

    if (method === 'delete') {
      // Delete record
      await investmentHorseShare.destroy();

    } else {
      investmentHorseShare.set('investmentHorse', investmentHorse);
      investmentHorseShare.set('sharePercentage', sharePercentage);
      investmentHorseShare.set('startDate', startDate);

      // Save transaction data
      const response = await investmentHorseShare.save();
    }

    return true;
  } catch (error) {
    console.error('saveInvestmentHorseShare error', error);
    return false;
  }
}

export default saveInvestmentHorseShare;
