import React, { useState } from 'react';
import { useHistory } from 'react-router';
import DateFnsUtils from '@date-io/date-fns';
import MaterialTable from 'material-table';

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import {
  ButtonWithLoading,
} from '../../../../components';

import {
  routes,
} from '../../../../const';

const HorseShares = ({
  dataCustomers,
  dataSyndications,
  isSaving,
  onCreate,
  onDelete,
  onUpdate,
}) => {

  const history = useHistory();

  const [ newData, setNewData ] = useState({
    customerId: '',
    startDate: new Date(),
    sharePercentage: 0,
  });

  const handleFieldChange = (name, value) => {
    setNewData({
      ...newData,
      [name]: value
    });
  }

  const handleClick = () => onCreate(newData);

  const columns = [
    { title: 'Customer', field: 'customer', type: 'string' },
    {
      title: 'Shares',
      field: 'sharePercentage',
      type: 'string',
      render: ({ sharePercentage }) => `${sharePercentage}%`,
    },
  ];

  return (
    <Card>
      <CardHeader title="Customer Shares" />
      <Divider />
      <CardContent style={{ padding: 0, paddingBottom: 0 }}>

        {/* Add data panel */}
        <div style={{ padding: 16 }}>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              xs={6}
              md={3}
            >
              <FormControl fullWidth>
                <InputLabel htmlFor="customerId">Customer</InputLabel>
                <Select
                  disabled={isSaving}
                  inputProps={{
                    name: 'customerId',
                    id: 'customerId'
                  }}
                  onChange={(event) => handleFieldChange('customerId', event.target.value)}
                  value={newData.customerId}
                >
                  {dataCustomers.map((customer) => (
                    <MenuItem
                      key={customer.id}
                      value={customer.id}
                    >
                      {customer.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={6}
              md={3}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disabled={isSaving}
                  disableToolbar
                  format="dd/MM/yyyy"
                  fullWidth
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  label="Start Date"
                  name="startDate"
                  onChange={(dateString) => handleFieldChange('startDate', dateString)}
                  value={newData.startDate}
                  variant="inline"
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid
              item
              xs={6}
              md={3}
            >
              <TextField
                disabled={isSaving}
                fullWidth
                label="Shares"
                name="sharePercentage"
                onChange={(event) => handleFieldChange('sharePercentage', event.target.value)}
                type="number"
                value={newData.sharePercentage}
              />
            </Grid>
            <Grid
              item
              xs={6}
              md={3}
            >
              <ButtonWithLoading
                color="primary"
                defaultLabel="Add Data"
                disabled={isSaving}
                isLoading={isSaving}
                loadingLabel="Adding..."
                onClick={handleClick}
                variant="contained"
              />
            </Grid>
          </Grid>
        </div>

        <Divider />

        {/* Table */}
        <MaterialTable
          actions={[
            {
              icon: 'edit',
              tooltip: 'Edit Syndication',
              onClick: (event, rowData) => history.push(`/syndication/${rowData.id}`)
            }
          ]}
          columns={columns}
          components={{
            Container: props => <div>{props.children}</div>,
          }}
          data={dataSyndications}
          editable={{
            onRowDelete: onDelete,
            onRowUpdate: onUpdate,
          }}
          options={{
            actionsColumnIndex: -1,
            search: false,
            paging: false,
            draggable: false,
            showTitle: false,
            toolbar: false,
          }}
        />
      </CardContent>
    </Card>
  );
}

export default HorseShares;
