import React, { useContext, useEffect } from 'react';
import {
  useHistory,
  useParams,
} from 'react-router';
import CustomerDetails from './CustomerDetails';

import { RouteContext } from '../../App';

export default function CustomerDetailsPageContainer() {

  // Current route
  const { setCurrentRoute } = useContext(RouteContext);

  useEffect(() => {
    setCurrentRoute('customers');
  }, []);

  // Customer data
  const { customerId, content } = useParams();

  // Tab data
  const [tabValue, setTabValue] = React.useState(0);
  const history = useHistory();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    pushBrowserHistory(newValue);
  };

  const pushBrowserHistory = (value) => {

    switch (value) {
      case 0:
        history.push(`/customer/${customerId}/profile`);
        break;
      case 1:
        history.push(`/customer/${customerId}/syndication`);
        break;
      case 2:
        history.push(`/customer/${customerId}/access`);
        break;
      default:
        history.push(`/customer/${customerId}/profile`);
        break;
    }
  }

  useEffect(() => {
    if (content === 'profile') {
      setTabValue(0);
    }

    if (content === 'syndication') {
      setTabValue(1);
    }

    if (content === 'access') {
      setTabValue(2);
    }
  }, []);

  return (
    <CustomerDetails
      handleTabChange={handleTabChange}
      tabValue={tabValue}
    />
  );
}
