import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import DateFnsUtils from '@date-io/date-fns';

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  TextField,
} from '@material-ui/core';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import MaterialTable from 'material-table';

import {
  ButtonWithLoading,
  ComboBox,
  Spinner,
} from '../../../../components';

import { routes } from '../../../../const';

const useStyles = makeStyles({
  action: {
    alignSelf: 'center',
    marginTop: 0,
  },
});

const AddInvestmentCustomerPanel = ({
  customerOptions,
  isSaving,
  newInvestmentCustomer,
  onAddInvestmentCustomer,
  onFieldChange,
}) => {
  return(
    <Grid
      container
      spacing={4}
    >
      <Grid
        item
        xs={6}
        md={3}
      >
        <ComboBox
          label="Select a customer"
          onChange={(event, customer) => onFieldChange('customerId', customer ? customer.id : null )}
          options={customerOptions}
        />
      </Grid>
      <Grid
        item
        xs={6}
        md={3}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            format="dd/MM/yyyy"
            fullWidth
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            label="Joined Date"
            name="joinedDate"
            onChange={(dateString) => onFieldChange('joinedDate', dateString)}
            value={newInvestmentCustomer.joinedDate}
            variant="inline"
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid
        item
        xs={6}
        md={3}
      >
        <TextField
          fullWidth
          label="Shares"
          name="shares"
          onChange={(event) => onFieldChange('shares', event.target.value)}
          type="number"
          value={newInvestmentCustomer.shares}
        />
      </Grid>
      <Grid
        item
        xs={6}
        md={3}
      >
        <ButtonWithLoading
          color="primary"
          defaultLabel="Add Customer"
          disabled={isSaving}
          isLoading={isSaving}
          loadingLabel="Adding..."
          onClick={onAddInvestmentCustomer}
          variant="contained"
        />
      </Grid>
    </Grid>
  );
}

const InvestmentCustomers = ({
  customerOptions,
  investmentCustomers,
  isLoading,
  isSaving,
  newInvestmentCustomer,
  onAddInvestmentCustomer,
  onAddInvestmentCustomerFieldChange,
  onDeleteInvestmentCustomer,
  onUpdateInvestmentCustomer,
}) => {

  const classes = useStyles();

  const columns = [
    {
      title: 'Customer Name',
      field: 'customerName',
      editable: 'never',
    },
    {
      title: 'Joined Date',
      field: 'joinedDate',
      type: 'date',
    },
    {
      title: 'Shares',
      field: 'shares',
      type: 'numeric',
      align: 'left',
    },
    {
      title: 'Total Income',
      field: 'totalIncome',
      editable: 'never',
      type: 'currency',
    },
    {
      title: 'Total Expense',
      field: 'totalExpense',
      editable: 'never',
      type: 'currency',
    },
  ];

  return (
    <>
      <Card>
        <CardHeader
          classes={{
            action: classes.action,
          }}
          title="Investment Customers"
        />
        <Divider />
        {isLoading ?
          <Spinner /> :
          <CardContent style={{ padding: 0, paddingBottom: 0 }}>
            <div style={{ padding: 16 }}>
              <AddInvestmentCustomerPanel
                customerOptions={customerOptions}
                isSaving={isSaving}
                newInvestmentCustomer={newInvestmentCustomer}
                onAddInvestmentCustomer={onAddInvestmentCustomer}
                onClick={onAddInvestmentCustomer}
                onFieldChange={onAddInvestmentCustomerFieldChange}
              />
            </div>
            <Divider />
            <MaterialTable
              columns={columns}
              components={{
                Container: props => <div>{props.children}</div>,
              }}
              data={investmentCustomers}
              editable={{
                onRowDelete: onDeleteInvestmentCustomer,
                onRowUpdate: onUpdateInvestmentCustomer,
              }}
              options={{
                actionsColumnIndex: -1,
                search: false,
                paging: false,
                draggable: false,
                showTitle: false,
                toolbar: false,
              }}
              title="Investment Horses"
            />
          </CardContent>
        }
      </Card>
    </>
  );
}

export default InvestmentCustomers;
