import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles(theme => ({
  background: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: theme.palette.error.dark,
  },
  closeIcon: {
    fontSize: 20,
  },
  leadingIcon: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const AuthenticationErrorNotification = ({
  onClose
}) => {

  const classes = useStyles();

  return(
    <SnackbarContent
      aria-describedby="client-snackbar"
      className={classes.background}
      message={
        <span
          className={classes.message}
          id="client-snackbar"
        >
          <ErrorIcon className={classes.leadingIcon} />
          Invalid Username/Password
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>,
      ]}
    />
  );
}

export default AuthenticationErrorNotification;
