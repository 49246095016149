import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const ComboBox = ({
  disabled,
  label,
  onChange,
  options,
}) => {
  return (
    <Autocomplete
      getOptionLabel={(option) => option.title}
      id="combo-box"
      onChange={onChange}
      options={options}
      renderInput={(params) => <TextField {...params} disabled={disabled} label={label} />}
      style={{ width: '100%' }}
    />
  );
}

export default ComboBox;
