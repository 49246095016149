import React, { useContext, useRef, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';

import { ButtonWithLoading, SubTitle } from '../../components';
import useStyles from './styles';

import DataContext from '../../utils/DataContext';

const CustomerProfile = () => {

  const classes = useStyles();

  const [error, setError] = useState(false);

  const {
    customer,
    handleCancel,
    handleDateFieldChange,
    handleFieldChange,
    handleSubmit,
    isSubmitting,
  } = useContext(DataContext);

  const {
    firstName,
    middleName,
    lastName,
    dateOfBirth,
    email,
    landline,
    mobile,
    businessName,
    jobTitle,
    invoiceTitle,
    bankAccount,
    gstNumber,
  } = customer;

  const scrollToRef = (ref) => {
    ref.current.scrollIntoView();
  }

  const formRef = useRef(null);

  const handleBeforeFormSubmit = (event) => {
    event.preventDefault();

    // If horse name is empty, display error state
    if (!firstName) {
      setError(true);
      scrollToRef(formRef);
      return;
    }

    // Clear error state if there's any
    if (error) setError(false);

    // Submit form
    handleSubmit();
  }

  return(
    <Paper
      className={classes.paper}
      ref={formRef}
    >
      <Grid
        container
        spacing={6}
      >
        <Grid
          className={classes.formGroup}
          item
          xs={6}
        >
          <SubTitle>Contact</SubTitle>
          <TextField
            error={error && !firstName}
            fullWidth
            helperText={error && !firstName ? 'This is a required field' : null}
            label="First Name"
            margin="normal"
            name="firstName"
            onChange={handleFieldChange}
            required
            value={firstName || ''}
          />
          <TextField
            fullWidth
            label="Middle Name"
            margin="normal"
            name="middleName"
            onChange={handleFieldChange}
            value={middleName || ''}
          />
          <TextField
            fullWidth
            label="Last Name"
            margin="normal"
            name="lastName"
            onChange={handleFieldChange}
            value={lastName || ''}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              format="dd/MM/yyyy"
              fullWidth
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              label="Date of Birth"
              margin="normal"
              name="dateOfBirth"
              onChange={handleDateFieldChange}
              value={dateOfBirth || null}
              variant="inline"
            />
          </MuiPickersUtilsProvider>
          <TextField
            fullWidth
            label="Email"
            margin="normal"
            name="email"
            onChange={handleFieldChange}
            value={email || ''}
          />
          <TextField
            fullWidth
            label="Landline"
            margin="normal"
            name="landline"
            onChange={handleFieldChange}
            value={landline || ''}
          />
          <TextField
            fullWidth
            label="Mobile"
            margin="normal"
            name="mobile"
            onChange={handleFieldChange}
            value={mobile || ''}
          />
        </Grid>
        <Grid
          className={classes.formGroup}
          item
          xs={6}
        >
          <SubTitle>Business</SubTitle>
          <TextField
            fullWidth
            label="Business Name"
            margin="normal"
            name="businessName"
            onChange={handleFieldChange}
            value={businessName || ''}
          />
          <TextField
            fullWidth
            label="Job Title"
            margin="normal"
            name="jobTitle"
            onChange={handleFieldChange}
            value={jobTitle || ''}
          />
          <TextField
            fullWidth
            label="Invoice Title"
            margin="normal"
            name="invoiceTitle"
            onChange={handleFieldChange}
            value={invoiceTitle || ''}
          />
          <TextField
            fullWidth
            label="Bank Account"
            margin="normal"
            name="bankAccount"
            onChange={handleFieldChange}
            value={bankAccount || ''}
          />
          <TextField
            fullWidth
            label="GST Number"
            margin="normal"
            name="gstNumber"
            onChange={handleFieldChange}
            value={gstNumber || ''}
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <ButtonWithLoading
          color="primary"
          defaultLabel="Save changes"
          isLoading={isSubmitting}
          loadingLabel="Saving changes..."
          onClick={handleBeforeFormSubmit}
          variant="contained"
        />
        <Button
          color="default"
          disabled={isSubmitting}
          onClick={handleCancel}
          style={{ marginLeft: 8 }}
        >
          Cancel
        </Button>
      </Grid>
    </Paper>
  );
}

export default CustomerProfile;
