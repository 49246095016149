import React, { useEffect, useState } from 'react';

import {
  deleteInvestmentCustomer,
  fetchCustomers,
  fetchInvestmentCustomers,
  saveInvestmentCustomer,
} from './actions';

import InvestmentCustomers from './InvestmentCustomers';

const defaultNewInvestmentCustomer = {
  customerId: null, // Customer id
  joinedDate: new Date(), // Default joined date
  shares: 1, // Default share
};

const InvestmentCustomersContainer = () => {


  const [ customers, setCustomers ] = useState([]);
  const [ customerOptions, setCustomerOptions ] = useState([]);
  const [ investmentCustomers, setInvestmentCustomers ] = useState([]);
  const [ newInvestmentCustomer, setNewInvestmentCustomer ] = useState(defaultNewInvestmentCustomer);

  const [ isLoading, setIsLoading ] = useState(true);
  const [ isSaving, setIsSaving ] = useState(false);

  const _initData = async () => {
    const [ customers, investmentCustomers ] = await Promise.all([
      fetchCustomers(),
      fetchInvestmentCustomers()
    ]);
    setCustomers(customers);
    setInvestmentCustomers(investmentCustomers);
    setCustomerOptions(_customerOptions(customers, investmentCustomers));
    setIsLoading(false);
  }

  useEffect(() => {
    _initData();
  }, []);

  /**
   * Material table d horse selector options
   * @returns {object}
   */
  const _customerOptions = (customers, investmentCustomers) => {
    const options = customers
      .filter(({id}) => !investmentCustomers.some(({customerId}) => customerId === id))
      .map(({id, customerName}) => ({ title: customerName, id: id }));
    return options;
  }

  const handleAddInvestmentCustomer = async () => {
    await _saveInvestmentCustomer(newInvestmentCustomer, 'add');
  }

  const _saveInvestmentCustomer = async (data, method) => {
    if (method === 'add') setIsSaving(true);
    const result = await saveInvestmentCustomer(data, method);
    setNewInvestmentCustomer(defaultNewInvestmentCustomer);
    setInvestmentCustomers(result);
    setCustomerOptions(_customerOptions(customers, result));
    if (method === 'add') setIsSaving(false);
  }

  const handleAddInvestmentCustomerFieldChange = (field, value) => {
    setNewInvestmentCustomer({
      ...newInvestmentCustomer,
      [field]: value,
    });
  }

  const handleDeleteInvestmentCustomer = async (oldData) => {
    const result = await deleteInvestmentCustomer(oldData);
    setInvestmentCustomers(result);
    setCustomerOptions(_customerOptions(customers, result));
  }

  const handleUpdateInvestmentCustomer = async (oldData) => {
    await _saveInvestmentCustomer(oldData, 'update');
  }


  return(
    <InvestmentCustomers
      customerOptions={customerOptions}
      investmentCustomers={investmentCustomers}
      isLoading={isLoading}
      isSaving={isSaving}
      newInvestmentCustomer={newInvestmentCustomer}
      onAddInvestmentCustomer={handleAddInvestmentCustomer}
      onAddInvestmentCustomerFieldChange={handleAddInvestmentCustomerFieldChange}
      onDeleteInvestmentCustomer={handleDeleteInvestmentCustomer}
      onUpdateInvestmentCustomer={handleUpdateInvestmentCustomer}
    />
  );
}

export default InvestmentCustomersContainer;
