import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3),
  },
  backLink: {
    display: 'inline-block',
    marginBottom: theme.spacing(3),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1) / -2,
  },
  formGroup: {
    marginBottom: theme.spacing(4),
  },
  tab: {
    backgroundColor: theme.palette.primary.dark,
  },
  addButton: {
    marginBottom: theme.spacing(3),
  },
  creditAmount: {
    color: '#e57373',
  },
  debitAmount: {
    color: '#4caf50',
  },
}));
