import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Parse from 'parse';

import { ThemeProvider } from '@material-ui/styles';

import Routes from './Routes';
import theme from './theme';

import { DataProvider } from './utils/DataContext';

export const RouteContext = React.createContext();

export default function App() {

  Parse.serverURL = process.env.REACT_APP_PARSE_SERVER_URL; // This is your Server URL
  Parse.initialize(
    process.env.REACT_APP_PARSE_APPLICATION_ID, // This is your Application ID
    process.env.REACT_APP_PARSE_JAVASCRIPT_KEY // This is your Javascript key
  );

  const [ currentRoute, setCurrentRoute ] = useState('customers');
  const [ isAuthenticated, setIsAuthenticated ] = useState(false);

  useEffect(() => {

    const currentUser = Parse.User.current();

    if (!currentUser) {
      Parse.User.logOut();
      return;
    }

    setIsAuthenticated(true)
  }, []);

  const contextValue = {
    currentRoute: currentRoute,
    setCurrentRoute: setCurrentRoute,
    isAuthenticated,
    setIsAuthenticated,
  }

  const testContextProvider = {
    test1: 'test1',
    test2: 'test2'
  }

  return (
    <ThemeProvider theme={theme}>
      <RouteContext.Provider value={contextValue}>
        <DataProvider value={testContextProvider}>
          <Router>
            <Routes isAuthenticated={isAuthenticated} />
          </Router>
        </DataProvider>
      </RouteContext.Provider>
    </ThemeProvider>
  );
}
