import Parse from 'parse';

async function fetchHorses() {
  try {
    const query = new Parse.Query('Horse');
    const response = await query.find();

    const payload = response.map((horse) => ({
      id: horse.id,
      horseName: horse.get('horseName'),
    }));

    return payload;

  } catch (error) {
    console.error('fetchHorses error', error);
    return [];
  }
}

export default fetchHorses;
