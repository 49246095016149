import Parse from 'parse';

async function fetchUser(customerId) {
  const query = new Parse.Query('Customer');

  query.include('user');

  const customer = await query.get(customerId);

  const user = customer.get('user');

  return user;
}

async function fetchPortalUserAction(customerId) {
  try {

    const user = await fetchUser(customerId);

    if (!user) return false;

    const payload = {
      id: user.id,
      username: user.get('username'),
      password: '',
    }

    return payload;

  } catch (error) {
    console.error('fetchPortalUserAction error', error);
    return false;
  }
}

export default fetchPortalUserAction;
