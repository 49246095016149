import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import MaterialTable from 'material-table';

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Link,
  TextField,
} from '@material-ui/core';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import {
  ButtonWithLoading,
  DataCard,
  Spinner,
} from '../../components';

import { routes } from '../../const';

const InvestmentHorseShares = ({
  horse,
  isLoading,
  isSaving,
  shareData,
  onCreateShareClick,
  onDelete,
  onFieldChange,
  onUpdate,
  shares,
}) => {

  const columns = [
    { title: 'Start Date', field: 'startDate', type: 'date' },
    { title: 'Share', field: 'sharePercentage', type: 'numeric', align: 'left' },
  ];

  return (
    <>
      <Grid item xs={12}>
        <Link component={RouterLink} to={routes.INVESTMENT}>
          Back to Investment Dashboard
        </Link>
      </Grid>
      <Grid item xs={12}>
        <DataCard
          linkURL={`${routes.HORSE_DETAILS}/${horse.horseId}`}
          title="Horse"
          value={horse.name}
        />
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="Investment Horse Shares" />
          <Divider />
          <CardContent style={{ padding: 0, paddingBottom: 0 }}>
            <div style={{ padding: 16 }}>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={6}
                  md={3}
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disabled={isSaving}
                      disableToolbar
                      format="dd/MM/yyyy"
                      fullWidth
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      label="Date"
                      name="startDate"
                      onChange={(dateString) => onFieldChange('startDate', dateString)}
                      value={shareData?.startDate}
                      variant="inline"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={3}
                >
                  <TextField
                    disabled={isSaving}
                    fullWidth
                    label="Shares"
                    name="sharePercentage"
                    onChange={(event) => onFieldChange('sharePercentage', event.target.value)}
                    type="number"
                    value={shareData.sharePercentage}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={3}
                >
                  <ButtonWithLoading
                    color="primary"
                    defaultLabel="Add Shares"
                    disabled={isSaving}
                    isLoading={isSaving}
                    loadingLabel="Adding..."
                    onClick={onCreateShareClick}
                    variant="contained"
                  />
                </Grid>
              </Grid>
            </div>

            <Divider />

            <MaterialTable
              columns={columns}
              data={shares}
              editable={{
                onRowDelete: onDelete,
                onRowUpdate: onUpdate,
              }}
              options={{
                actionsColumnIndex: -1,
                search: false,
                paging: false,
                draggable: false,
                showTitle: false,
                toolbar: false,
              }}
              title="Investment Horse Shares"
            />

          </CardContent>
        </Card>
      </Grid>
    </>
  );
}

export default InvestmentHorseShares;
