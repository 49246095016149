import Parse from 'parse';

async function deleteHorse(horseId) {
  try {
    const Horse = Parse.Object.extend('Horse');
    const horse = new Horse();
    horse.id = horseId;

    const syndicationQuery = new Parse.Query('Syndication');
    syndicationQuery.equalTo('horse', horse);
    const syndication = await syndicationQuery.first();

    if (syndication !== undefined && syndication !== null) {
      throw new Error('Horse has syndication!');
    }

    const deletedHorse = await horse.destroy();

  } catch (error) {
    console.error('deleteHorse error', error);
  }
}

export default deleteHorse;
