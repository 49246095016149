/* eslint-disable no-script-url */

import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import MenuIcon from '@material-ui/icons/Menu';

import useStyles from './styles';

export default function TopBar({ handleDrawerToggle }) {

  const classes = useStyles();

  return (
    <AppBar
      position="fixed"
      className={classes.appBar}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
          Taniwha Group CRM Dashboard
        </Typography>
        {/* <IconButton color="inherit">
          <AccountCircleIcon />
        </IconButton> */}
      </Toolbar>
    </AppBar>
  );
}
