import React, { useEffect, useState } from 'react';

import {
  deleteCompanyStatement,
  fetchCompanyStatement,
  saveCompanyStatement,
} from './actions';

import CompanyStatements from './CompanyStatements';

const defaultStatement = {
  statementDate: new Date(),
  totalCurrentAssets: 0,
  totalAssets: 0,
  totalCurrentLiabilities: 0,
  totalLiabilities: 0,
  totalEquity: 0,
  totalTradingIncome: 0,
  grossProfit: 0,
  netProfit: 0,
  netCashFlowsFromOperatingActivities: 0,
  supplierPurchase: 0,
  creditSales: 0,
  averageAccountsPayable: 0,
  avarageAccountsReceivable: 0,
}

const CompanyStatementsContainer = () => {

  const [ statements, setStatements ] = useState([]);
  const [ newStatement, setNewStatement ] = useState(defaultStatement);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ isSaving, setIsSaving ] = useState(false);

  async function _fetchGeneralConfig() {
    const response = await fetchCompanyStatement();
    setStatements(response);
    setIsLoading(false);
  }

  useEffect(() => {
    _fetchGeneralConfig();
  }, []);

  const handleUpdateStatement = async (newData, oldData) => {

    const result = await saveCompanyStatement(newData);

    const clonedStatements = [...statements];

    clonedStatements[clonedStatements.indexOf(oldData)] = result;

    setStatements(clonedStatements);

  }

  const handleAddStatement = async () => {
    setIsSaving(true);
    const result = await saveCompanyStatement(newStatement);
    const clonedStatements = [...statements];
    clonedStatements.push(result);
    setStatements(clonedStatements);
    setNewStatement(defaultStatement);
    setIsSaving(false);
  }

  const handleDeleteStatement = async (oldData) => {
    await deleteCompanyStatement(oldData);
    const clonedStatements = [...statements];
    clonedStatements.splice(clonedStatements.indexOf(oldData), 1);
    setStatements(clonedStatements);
  }

  const handleStatementFieldChange = (field, value) => {
    setNewStatement({
      ...newStatement,
      [field]: value,
    });
  }

  return(
    <CompanyStatements
      isLoading={isLoading}
      isSaving={isSaving}
      newStatement={newStatement}
      onAddStatement={handleAddStatement}
      onDeleteStatement={handleDeleteStatement}
      onStatementFieldChange={handleStatementFieldChange}
      onUpdateStatement={handleUpdateStatement}
      statements={statements}
    />
  );
}

export default CompanyStatementsContainer;
