/* eslint-disable react/no-multi-comp */
import React, { useState } from 'react';

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  TextField,
} from '@material-ui/core';

import MarketTable from '../MarketTable';

import {
  ButtonWithLoading,
  Spinner,
} from '../../../../components';

const useStyles = makeStyles({
  action: {
    alignSelf: 'center',
    marginTop: 0,
  },
});

const FiveCountriesTable = ({
  data: marketData,
  isLoading,
  isSaving,
  onAddData,
  onDeleteData,
  onUpdateData,
  title,
  type,
}) => {

  const classes = useStyles();

  const columns = [
    {
      title: 'Year',
      field: 'year',
    },
    {
      title: 'Australia',
      field: 'au',
      type: 'currency',
      align: 'left',
    },
    {
      title: 'New Zealand',
      field: 'nz',
      type: 'currency',
      align: 'left',
    },
    {
      title: 'Singapore',
      field: 'sg',
      type: 'currency',
      align: 'left',
    },
    {
      title: 'Japan',
      field: 'jp',
      type: 'currency',
      align: 'left',
    },
    {
      title: 'Hong Kong',
      field: 'hk',
      type: 'currency',
      align: 'left',
    },
  ];

  const [ newData, setNewData ] = useState({
    year: parseInt(new Date().getFullYear()),
    type: type,
    data: {
      au: 0,
      nz: 0,
      sg: 0,
      jp: 0,
      hk: 0,
    }
  });

  const handleFieldChange = (name, value) => {
    if (name === 'year') {
      setNewData({
        ...newData,
        year: parseInt(value) || 0,
      });
    } else {
      setNewData({
        ...newData,
        data: {
          ...newData.data,
          [name]: parseFloat(value) || 0,
        }
      })
    }
  }

  const handleClick = () => onAddData(newData);

  return (
    <>
      <Card>
        <CardHeader
          classes={{
            action: classes.action,
          }}
          title={title}
        />
        <Divider />
        {isLoading ?
          <Spinner /> :
          <CardContent style={{ padding: 0, paddingBottom: 0 }}>

            {/* Add data panel */}
            <div style={{ padding: 16 }}>
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  xs={6}
                  md={2}
                >
                  <TextField
                    disabled={isSaving}
                    fullWidth
                    label="Year"
                    name="year"
                    onChange={(event) => handleFieldChange('year', event.target.value)}
                    type="number"
                    value={newData.year}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={2}
                >
                  <TextField
                    disabled={isSaving}
                    fullWidth
                    label="Australia"
                    name="au"
                    onChange={(event) => handleFieldChange('au', event.target.value)}
                    type="number"
                    value={newData.data.au}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={2}
                >
                  <TextField
                    disabled={isSaving}
                    fullWidth
                    label="New Zealand"
                    name="nz"
                    onChange={(event) => handleFieldChange('nz', event.target.value)}
                    type="number"
                    value={newData.data.nz}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={2}
                >
                  <TextField
                    disabled={isSaving}
                    fullWidth
                    label="Singapore"
                    name="sg"
                    onChange={(event) => handleFieldChange('sg', event.target.value)}
                    type="number"
                    value={newData.data.sg}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={2}
                >
                  <TextField
                    disabled={isSaving}
                    fullWidth
                    label="Japan"
                    name="jp"
                    onChange={(event) => handleFieldChange('jp', event.target.value)}
                    type="number"
                    value={newData.data.jp}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={2}
                >
                  <TextField
                    disabled={isSaving}
                    fullWidth
                    label="Hong Kong"
                    name="hk"
                    onChange={(event) => handleFieldChange('hk', event.target.value)}
                    type="number"
                    value={newData.data.hk}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={2}
                >
                  <ButtonWithLoading
                    color="primary"
                    defaultLabel="Add Data"
                    disabled={isSaving}
                    isLoading={isSaving}
                    loadingLabel="Adding..."
                    onClick={handleClick}
                    variant="contained"
                  />
                </Grid>
              </Grid>
            </div>

            <Divider />

            {/* Table */}
            <MarketTable
              columns={columns}
              data={marketData}
              onDeleteData={onDeleteData}
              onUpdateData={onUpdateData}
            />

          </CardContent>
        }
      </Card>
    </>
  );
}

export default FiveCountriesTable;
