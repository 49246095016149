import Parse from 'parse';

async function deleteHorseNews(newsData, successCallBack) {
  const News = Parse.Object.extend('News');
  const query = new Parse.Query(News);
  const news = await query.get(newsData.id);

  const result = await news.destroy();
  if (successCallBack) successCallBack();
}

export default deleteHorseNews;
