import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import {
  fetchInvestmentHorse,
  fetchInvestmentHorseShares,
  saveInvestmentHorseShare
} from './actions';

import InvestmentHorseShares from './InvestmentHorseShares';

import { parseFloatNumber } from '../../utils';

/**
 * Investment Horse Shares Container
 */
const InvestmentHorseSharesContainer = () => {

  // Query Params
  const { investmentHorseId } = useParams();

  // States
  const [ horse, setHorse ] = useState({});
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isSaving, setIsSaving ] = useState(false);
  const [ shareData, setShareData ] = useState({
    sharePercentage: '0',
    startDate: new Date(),
  });
  const [ shares, setShares ] = useState([]);

  /**
   * Init Data
   */
  const initData = async () => {
    try {
      setIsLoading(true);
      const [
        investmentHorseRecord,
        sharesRecords,
      ] = await Promise.all([
        fetchInvestmentHorse(investmentHorseId),
        fetchInvestmentHorseShares(investmentHorseId),
      ]);
      setHorse(investmentHorseRecord);
      setShares(sharesRecords);
    } catch (error) {
      console.error('initData error', error);
    } finally {
      setIsLoading(false);
    }
  }

  /**
   * Handle Field Change
   * @param {string} field
   * @param {string} value
   */
  const handleFieldChange = (field, value) => {
    setShareData({
      ...shareData,
      [field]: value,
    });
  }

  /**
   * handle Create
   */
  const handeCreate = async () => {
    setIsSaving(true);
    await _saveInvestmentHorseShare(shareData, 'create');
    setIsSaving(false);
  }

  /**
   * handle Delete
   * @param {object} data
   */
  const handleDelete = async (data) => {
    await _saveInvestmentHorseShare(data, 'delete');
  }

  /**
   * handle Update
   * @param {object} data
   */
  const handleUpdate = async (data) => {
    await _saveInvestmentHorseShare(data, 'update');
  }

  /**
   * Save Investment Horse Shares
   * @param {string} method - create | update | delete
   * @return {boolean}
   */
  const _saveInvestmentHorseShare = async (data, method) => {
    try {
      const { id = '', sharePercentage, startDate } = data;

      const response = await saveInvestmentHorseShare({
        investmentHorseId,
        investmentHorseShareId: id,
        sharePercentage: parseFloatNumber(sharePercentage),
        startDate: method === 'update' ? new Date(startDate) : startDate,
      }, method);

      if (!response) throw new Error();

      const sharesRecords = await fetchInvestmentHorseShares(investmentHorseId);
      setShares(sharesRecords);

      return true;
    } catch (error) {
      console.error('Save Investment Horse Shares error', error);
      return false;
    }
  }

  useEffect(() => {
    initData();
  }, []);

  return (
    <>
      <InvestmentHorseShares
        horse={horse}
        isLoading={isLoading}
        isSaving={isSaving}
        onCreateShareClick={handeCreate}
        onDelete={handleDelete}
        onFieldChange={handleFieldChange}
        onUpdate={handleUpdate}
        shareData={shareData}
        shares={shares}
      />
    </>
  );
}

export default InvestmentHorseSharesContainer;
