import {
  fetchCustomers,
  fetchSyndications,
} from '../actions';

async function fetchSyndicationsInitData(horseId) {
  try {
    const [
      customers,
      syndications,
    ] = await Promise.all([
      fetchCustomers(),
      fetchSyndications(horseId),
    ]);

    // Remove syndication customer
    const filteredCustomers = customers.filter((customer) =>
      !syndications.some((syndication) => (syndication.customerId === customer.id))
    );

    const payload = {
      syndications,
      customers: filteredCustomers,
    }

    return payload;

  } catch (error) {
    console.error('fetchSyndicationsInitData error', error);
    return [];
  }
}

export default fetchSyndicationsInitData;
