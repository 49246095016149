import Parse from 'parse';

async function fetchHorseNewsDetails(newsId) {
  const News = Parse.Object.extend('News');
  const query = new Parse.Query(News);
  query.equalTo('objectId', newsId);
  const response = await query.first();

  const payload = {
    id: response.id,
    date: response.get('date'),
    title: response.get('title'),
    description: response.get('description'),
    image: response.get('image'),
    linkURL: response.get('linkURL'),
  };

  return payload;
}

export default fetchHorseNewsDetails;
