import React, { useContext, useEffect, useState } from 'react';

import {
  updateGeneralConfig,
} from './actions';

import GeneralConfig from './GeneralConfig';

import { InvestmentContext } from '../../InvestmentContainer';

const GeneralConfigContainer = () => {

  // Rename investmentProduct to product to avoid conflict
  const { investmentProduct: product } = useContext(InvestmentContext);

  const [ investmentProduct, setInvestmentProduct ] = useState({});
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(true);

  function _fetchGeneralConfig() {
    setInvestmentProduct(product);
    setIsLoading(false);
  }

  useEffect(() => {
    _fetchGeneralConfig();
  }, []);

  const handleFieldChange = (event) => {

    const { name, value } = event.target;

    const newState = {
      ...investmentProduct,
      [name]: parseFloat(value)
    }
    setInvestmentProduct(newState);
  }

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await updateGeneralConfig(investmentProduct);
    setIsSubmitting(false);
  }

  return(
    <GeneralConfig
      investmentProduct={investmentProduct}
      isLoading={isLoading}
      isSubmitting={isSubmitting}
      onFieldChange={handleFieldChange}
      onSubmit={handleSubmit}
    />
  );
}

export default GeneralConfigContainer;
