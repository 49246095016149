import {
  fetchHorses,
  fetchSyndications,
} from '../actions';

async function fetchSyndicationsInitData(customerId) {
  try {
    const [
      horses,
      syndications,
    ] = await Promise.all([
      fetchHorses(),
      fetchSyndications(customerId),
    ]);

    // Remove syndication customer
    const filteredHorses = horses.filter((horse) =>
      !syndications.some((syndication) => (syndication.horseId === horse.id))
    );

    const payload = {
      syndications,
      horses: filteredHorses,
    }

    return payload;

  } catch (error) {
    console.error('fetchSyndicationsInitData error', error);
    return [];
  }
}

export default fetchSyndicationsInitData;
