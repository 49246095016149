import Parse from 'parse';

async function fetchSyndicationInitData(syndicationId) {
  try {

    const payload = await Parse.Cloud.run('fetchSyndicationInitData', {syndicationId});

    return payload;

  } catch (error) {
    console.error('fetchSyndicationInitData error', error);

    // Return empty array on error
    return {};
  }

}

export default fetchSyndicationInitData;
