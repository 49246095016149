import Parse from 'parse';

async function fetchCompanyTransactions() {
  try {

    const payload = await Parse.Cloud.run('fetchCompanyTransactions');
    return payload;

  } catch (error) {
    console.error('fetchCompanyTransactions error', error);
    return [];
  }
}

export default fetchCompanyTransactions;
