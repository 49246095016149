import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import currency from 'currency.js';

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Link,
  makeStyles,
  TextField,
} from '@material-ui/core';

import {
  Edit as EditIcon,
} from '@material-ui/icons';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import MaterialTable from 'material-table';

import {
  ButtonWithLoading,
  ComboBox,
  Spinner,
} from '../../../../components';

import { routes } from '../../../../const';

const useStyles = makeStyles({
  action: {
    alignSelf: 'center',
    marginTop: 0,
  },
});

const InvestmentHorses = ({
  horseOptions,
  investmentHorses,
  isLoading,
  isSaving,
  onEdit,
  onDeleteData,
  onAddData,
}) => {

  const classes = useStyles();

  const columns = [
    {
      title: 'Horse Name',
      field: 'horseName',
      editable: 'never',
      render: ({ horseId, horseName }) => <Link component={RouterLink} to={`${routes.HORSE_DETAILS}/${horseId}`}>{horseName}</Link>,
    },
    {
      title: 'Gender',
      field: 'gender',
      editable: 'never',
    },
    {
      title: 'Horse Type',
      field: 'horseType',
      editable: 'never',
    },
    {
      title: 'Horse Value',
      field: 'horseValue',
      editable: 'never',
      render: ({ horseValue }) => currency(horseValue, { formatWithSymbol: true }).format(),
    },
    {
      title: 'Investment Value',
      field: 'investmentValue',
      editable: 'never',
      render: ({ investmentValue }) => currency(investmentValue, { formatWithSymbol: true }).format(),
    },
    {
      title: 'Shares',
      field: 'shares',
      type: 'numeric',
      align: 'left',
      render: ({ shares }) => shares + '%',
    },
  ];

  const [ newData, setNewData ] = useState({
    horseId: '',
    shares: 100,
    startDate: new Date(),
  });

  const handleFieldChange = (name, value) => {
    setNewData({
      ...newData,
      [name]: value
    });
  }

  const handleClick = () => onAddData(newData);

  return (
    <>
      <Card>
        <CardHeader
          classes={{
            action: classes.action,
          }}
          title="Investment Horses"
        />
        <Divider />
        {isLoading ?
          <Spinner /> :
          <CardContent style={{ padding: 0, paddingBottom: 0 }}>
            <div style={{ padding: 16 }}>
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  xs={6}
                  md={3}
                >
                  <ComboBox
                    disabled={isSaving}
                    label="Select a horse"
                    onChange={(event, horse) => handleFieldChange('horseId', horse ? horse.id : null )}
                    options={horseOptions}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={3}
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disabled={isSaving}
                      disableToolbar
                      format="dd/MM/yyyy"
                      fullWidth
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      label="Date"
                      name="startDate"
                      onChange={(dateString) => handleFieldChange('startDate', dateString)}
                      value={newData?.startDate}
                      variant="inline"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={3}
                >
                  <TextField
                    disabled={isSaving}
                    fullWidth
                    label="Shares"
                    name="shares"
                    onChange={(event) => handleFieldChange('shares', event.target.value)}
                    type="number"
                    value={newData.shares}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={3}
                >
                  <ButtonWithLoading
                    color="primary"
                    defaultLabel="Add Horse"
                    disabled={isSaving}
                    isLoading={isSaving}
                    loadingLabel="Adding..."
                    onClick={handleClick}
                    variant="contained"
                  />
                </Grid>
              </Grid>
            </div>
            <Divider />
            <MaterialTable
              actions={[
                {
                  icon: 'edit',
                  tooltip: 'Edit Investment Horse Shares',
                  onClick: onEdit,
                }
              ]}
              columns={columns}
              components={{
                Container: props => <div>{props.children}</div>,
              }}
              data={investmentHorses}
              editable={{
                onRowDelete: onDeleteData,
              }}
              options={{
                actionsColumnIndex: -1,
                search: false,
                paging: false,
                draggable: false,
                showTitle: false,
                toolbar: false,
              }}
              title="Investment Horses"
            />
          </CardContent>
        }
      </Card>
    </>
  );
}

export default InvestmentHorses;
