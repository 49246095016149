const formatDate = (timestamp) => {

  if (!timestamp) return null;

  const dateObj = new Date(timestamp);

  const year = dateObj.getFullYear();
  const month = dateObj.getMonth() + 1;
  const date = dateObj.getDate();

  return `${year}-${month}-${date}`;
}

export default formatDate;
